import { useState, useEffect, useContext } from "react";
import { useAuth } from "../../contexts/Auth";
import colors from "../../config/colors";
import { AppContext } from "../../contexts/AppContext";

function WelcomeScreen() {
  const appContext = useContext(AppContext);
  const { user } = useAuth(); // pass from parent component?
  const [showWelcome, setShowWelcome] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let welcomeShown = localStorage.getItem("welcomeShown");
    if (welcomeShown && welcomeShown === "false") {
      setShowWelcome(true);
    }

    // getData();
    setLoading(false);
  }, []);

  // const getData = async () => {
  //   await appContext.getConsecutiveDays();
  //   await appContext.calculateScore();
  //   await appContext.getInviteCount();
  //   await appContext.getPuzzles(appContext.previousDays);
  //   await appContext.getPlayedCountsList(appContext.previousDays);
  //   await appContext.getMyProfile();
  //   await appContext.getMyLanguage();
  //   setLoading(false);
  // }

  return (
    (showWelcome && <div
      style={{
        ...styles.welcomeScreen,
        display: "flex",
      }}
      onClick={() => {
        localStorage.setItem("welcomeShown", "true");
        setShowWelcome(false);
      }}
    >
      {!loading ? <>
        <p>Welcome back to</p>
        <p style={{ fontWeight: "bold" }}>Subjects & Objects,</p>
        <p style={{ fontWeight: "bold", color: colors.link }}>
          {appContext.myProfile.username}
        </p>
        <p
          style={{
            color: "rgba(93, 100, 118, 0.8)",
            fontSize: 20,
            marginTop: 20,
          }}
        >
          Consecutive days playing a new game:{" "}
          <b>{appContext.consecutiveDays}</b>
        </p>
        <p className="text-primary" style={{
          color: "rgba(93, 100, 118, 0.8)",
          fontSize: 20,
          marginTop: 10,
        }}>Tap to continue</p>
      </> : "loading ..."}
    </div>
    ))
}

export default WelcomeScreen;

const styles = {
  welcomeScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#F0F6FC",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: colors.header,
    fontSize: 36,
    cursor: "pointer",
    zIndex: 3,
  },
};
