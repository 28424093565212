import colors from "../../config/colors";

function Button({
  fontSize,
  fontWeight,
  buttonText = "Button",
  width,
  secondaryButton,
  func = () => console.log("Pressed button"),
  disabled,
}) {
  return (
    <button
      style={{
        ...(disabled
          ? styles.disabledButton
          : secondaryButton
          ? styles.secondaryButton
          : styles.button),
        fontSize: fontSize ? fontSize : 18,
        fontWeight: fontWeight ? fontWeight : 600,
        width: width ? width : "100%",
      }}
      onClick={func}
      disabled={disabled}
    >
      {buttonText}
    </button>
  );
}

export default Button;

const BUTTONHEIGHT = 65;

const styles = {
  button: {
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    width: 310,
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
  secondaryButton: {
    backgroundColor: colors.secondaryButton,
    color: colors.buttonText,
    cursor: "pointer",
    width: 310,
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
  disabledButton: {
    backgroundColor: colors.disabledButton,
    color: colors.buttonText,
    cursor: "not-allowed",
    width: 310,
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
};
