import colors from "../../config/colors";
import { useTranslation } from "react-i18next";

function PromptScreen({
  phrase = "Phrase",
  showPrompt,
  setShowPrompt,
  questionNumber,
}) {
  const { t } = useTranslation();
  /* 
    If PromptScreen needs to automatically close, make DELAY a prop from parent component
  */
  return (
    <div
      style={{
        ...styles.promptScreen,
        display: showPrompt ? "flex" : "none",
      }}
      onClick={() => setShowPrompt(false)}
    >
      {questionNumber ? (
        <div style={{ textAlign: "center" }}>
          {/* Phrase {questionNumber} is */}
          {t("Phrase is")}
          <p style={styles.phrase}>{t(phrase)}</p>
          {t("Press anywhere to begin.")}
        </div>
      ) : (
        "Loading.."
      )}
    </div>
  );
}

export default PromptScreen;

const styles = {
  promptScreen: {
    backgroundColor: colors.bgLight,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    cursor: "pointer",
  },
  phrase: {
    fontSize: 36,
    fontWeight: "bold",
    color: colors.phrase,
  },
};
