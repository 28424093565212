import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import { IoMdSettings } from "react-icons/io";
import HelpModal from "../HelpModal/HelpModal";
// import { GrScorecard } from "react-icons/gr";
import { FaQuestion } from "react-icons/fa";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { IoCloseSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import "./MyNavbar.scss";
import { AppContext } from "../../contexts/AppContext";

// import { countries } from "countries-list";
// import "country-flag-icons/3x2/flags.css";

const locales = {
  en: 'English',
  fr: "French",
  cn: "Chinese"
}

function MyNavbar() {
  const appContext = useContext(AppContext);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // Get current user and signOut function from context
  const { signOut } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);

  let url = window.location.pathname;
  const [isGameScreen, setIsGameScreen] = useState(false);

  async function handleSignOut() {
    // console.log("signing out");
    setIsOpen(false);
    localStorage.setItem("splashShown", "false");

    // Ends user session
    await signOut();

    // Redirects the user to Login page
    navigate("/signin");
  }
  useEffect(() => {
    if (url) {
      if (
        url.includes("game") ||
        url.includes("demo") ||
        url.includes("referral") ||
        url.includes("score")
      ) {
        setIsGameScreen(true);
      } else {
        setIsGameScreen(false);
      }
    }
  }, [url]);

  // const warn = () => {
  //   if (isGameScreen) {
  //     let confirm = window.confirm("Leave page?");
  //     if (confirm) {
  //       navigate("/");
  //     }
  //   }
  // };

  return (!url.includes('profile') && !url.includes('language') ? <div
    style={{
      ...styles.navbar,
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <HelpModal
      showModal={showModal}
      setShowModal={setShowModal}
      path={url.includes("demo") ? "demo" : url.includes("game") ? "game" : ""}
    />
    <div className="navbar-wrapper"
      style={{
        maxWidth: appContext.SCREEN_WIDTH
      }}
    >
      <div
        className="align-items-center"
        style={{
          display: "flex",
          // padding: "0px 20px",
          // backgroundColor:'red',
          justifyContent: "space-between",
          width: "100%",
          paddingTop: 25,
          paddingLeft: "5%",
          paddingRight: "5%",
          position: "relative",
        }}
      >
        <div>
          {!!user && <Link to="/settings">
            <IoMdSettings
              style={{
                width: 40,
                height: 40,
                cursor: "pointer",
                color: "5D6476",
                marginRight: 10,
              }}
            />
          </Link>}
          {(url.includes("game") || url.includes("demo")) && <FaQuestion
            style={{
              width: 40,
              height: 40,
              cursor: "pointer",
              color: "5D6476",
              marginRight: 10,
            }}
            onClick={() => setShowModal(!showModal)}
          />}
          {/* {!!user && <Link to="/score">
            <GrScorecard
              style={{
                width: 40,
                height: 40,
                cursor: "pointer",
                color: "5D6476",
                marginRight: 10,
              }}
            />
          </Link>} */}
        </div>
        {/* <BiHelpCircle style={{width: isGameScreen?48:0, height:isGameScreen? 42:0, cursor: 'pointer', color: "5D6476"}} onClick={() => setShowModal(!showModal)}/> */}
        <div className="language-selector-wrapper">
          {/* <GiHamburgerMenu
            style={{
              width: 40,
              height: 40,
              cursor: "pointer",
              color: "5D6476",
              marginRight: 10,
            }}
            onClick={() => setIsOpen(true)}
          /> */}
          <select
            value={i18n.resolvedLanguage}
            onChange={(e) => {
              if (e.target.value === "en") {
                i18n.changeLanguage(e.target.value);
                // setIsOpen(false);
              } else {
                alert("French and Chinese games coming soon.");
              }
            }}
          >
            {Object.keys(locales).map(locale => (
              <option key={locale} value={locale}>{locales[locale]}</option>
            ))}
          </select>
        </div>
        {/* {isOpen && <div className="off-screen-wrapper">
          <div className="off-screen-menu">
            <div className="close-wrapper">
              <IoCloseSharp
                style={{
                  width: 40,
                  height: 40,
                  cursor: "pointer",
                  color: "5D6476",
                  marginRight: 10,
                }}
                onClick={() => setIsOpen(false)}
              />
            </div>
            <ul>
              <li className="off-screen-item language-selector-wrapper">
                <label>Language: </label>
                <select
                  value={i18n.resolvedLanguage}
                  onChange={(e) => {
                    i18n.changeLanguage(e.target.value);
                    setIsOpen(false);
                  }}
                >
                  {Object.keys(locales).map(locale => (
                    <option key={locale} value={locale}>{locales[locale]}</option>
                  ))}
                </select>
              </li>
              <li className="off-screen-item">
                <Link to="/" onClick={() => setIsOpen(false)}>Home</Link>
              </li>
              <li className="off-screen-item">
                <Link to="/upgrades" onClick={() => setIsOpen(false)}>Upgrades</Link>
              </li>
              {!user && <li className="off-screen-item">
                <Link to="/signin" className="text-primary" onClick={() => setIsOpen(false)}>Sign In</Link>
              </li>}
              {!user && <li className="off-screen-item">
                <Link to="/signup" className="text-primary" onClick={() => setIsOpen(false)}>Sign Up</Link>
              </li>}
              {!!user && <li className="off-screen-item">
                <Link
                  onClick={handleSignOut}
                  className="text-danger"
                >
                  Sign Out
                </Link>
              </li>}
            </ul>
          </div>
        </div>} */}
      </div>
    </div>
  </div> : <></>
  )
}

export default MyNavbar;

const styles = {
  navbar: {
    position: "relative",
    height: 50,
    width: "100%",
    display: "flex",
    // backgroundColor: 'red',
  },
};
