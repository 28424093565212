import { useState, useEffect } from "react";
import colors from "../../config/colors";

function Splash() {
  const [showSplash, setShowSplash] = useState(false);
  const DELAY = 3500; // Display Splash for this amount of milliseconds

  useEffect(() => {
    let splashShown = localStorage.getItem("splashShown");
    if (!splashShown || (splashShown && splashShown === "false")) {
      setShowSplash(true);
      setTimeout(() => {
        setShowSplash(false);
        localStorage.setItem("splashShown", "true");
      }, DELAY);
    }
  }, []);

  return (
    <div
      style={{ ...styles.splashScreen, display: showSplash ? "flex" : "none" }}
    >
      <img
        style={styles.logo}
        alt="Subjects & Objects"
        src="/images/logo.png"
      />
    </div>
  );
}

export default Splash;

const styles = {
  splashScreen: {
    backgroundColor: colors.bgDark,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 3,
  },
  logo: {
    width: 150,
    height: 152,
  },
};
