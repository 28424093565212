import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate, 
} from "react-router-dom";
import Splash from "../Splash";
import IntroScreen from "../IntroScreen";
import FreeGame from "../FreeGame/FreeGame";
import GameScreen from "../GameScreen/GameScreen";
import SignUp from "../SignUp/SignUp";
import SignIn from "../SignIn/SignIn";
import Settings from "../Settings/Settings";
import MyNavbar from "../MyNavbar/MyNavbar";
import Profile from "../Profile/Profile";
import Language from "../Language/Language";
import Home from "../Home/Home";
import Error from "../Error";
import DemoScreen from "../DemoScreen/DemoScreen";
import UpgradeScreen from "../UpgradeScreen/UpgradeScreen";
import ScoreBoard from "../ScoreBoard/ScoreBoard";
import Statistics from "../Statistics/Statistics";
import { useAuth } from "../../contexts/Auth";
import InvitationScreen from "../Referral/InvitationScreen";
import { AppContext, AppProvider } from "../../contexts/AppContext";
import { useEffect } from "react";
import ResetPassword from "../ResetPassword/ResetPassword"
import UpdatePassword from "../UpdatePassword/UpdatePassword"
import WelcomeScreen from "../WelcomeScreen/WelcomeScreen";
import ConfirmInvite from "../ConfirmInvite/ConfirmInvite";
import Leaderboard from "../Leaderboard/Leaderboard";

const ProtectedRoute = ({ user, children }) => {
  if (!user) {
    return <Navigate to="/" replace />;
  }
  return children;
};

function App() {
  // const ctx = useContext(AppContext);
  const { user } = useAuth();
  // <AppProvider></AppProvider>
  // introduces easier way to pass props to children without
  //  having to pass them through params manually
  // import AppContext from ../../contexts/AppContext and
  // call using a const ctx = useContext(AppContext)

  return (
    <AppProvider>
      <Router>
        <Splash />
        {!!user && <WelcomeScreen />}
        {/* {!!user && <Leaderboard />} */}
        <MyNavbar />
        <Routes>
          <Route path="/" element={<Home user={user} />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route path="/demo" element={<DemoScreen />} />
          <Route path="/freegame" element={<FreeGame />} />
          <Route path="*" element={<Error />} />
          {!user ? <>
            <Route path="/signup" element={<SignUp user={user} />} />
            <Route path="/signin" element={<SignIn user={user} />} />
            <Route path="/confirm-invite/:email/:referralId" element={<ConfirmInvite user={user} />} />
          </>
            : <>
              <Route path="/referral" element={<InvitationScreen user={user} />} />
              <Route path="/game" element={<GameScreen user={user} />} />
              {/* <Route path="/intro" element={<IntroScreen />} /> */}
              {/* <Route path="/prompt" element={<PromptScreen />} /> */}
              {/* <Route path="/modal" element={<Modal />} /> */}
              {/* <Route path="/signup/:referralId" element={<SignUp user={user} />} /> */}
              <Route path="/score" element={<ScoreBoard user={user} />} />
              {/* <Route path="/demographics" element={<Demographics />} /> */}
              <Route path="/settings" element={<Settings />} />
              <Route path="/profile" element={<Profile user={user} />} />
              <Route path="/language" element={<Language user={user} />} />
              <Route path="/statistics" element={<Statistics user={user} />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route
                path="/upgrades"
                element={
                  <ProtectedRoute user={user}>
                    <UpgradeScreen />
                  </ProtectedRoute>
                }
              />
            </>}
        </Routes>
      </Router>
    </AppProvider>
  );
}

export default App;
