import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { COUNTRIES, PROVINCES, EDUCATION, STATES } from "../../utils/data";
import { supabase } from "../../supabase";
import colors from "../../config/colors";
import Input from "../Input";
import { AppContext } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/Auth";

export default function Profile({ }) {
  const appContext = useContext(AppContext);
  const authContext = useAuth();
  const [profile, setProfile] = useState({});
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [states, setStates] = useState([]);
  const [education, setEducation] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setProfile(appContext.myProfile);
  }, []);

  useEffect(() => {
    setCountries(COUNTRIES);
    setProvinces(PROVINCES);
    setEducation(EDUCATION);
    setStates(STATES);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    const { error } = await appContext.updateProfile({
      birth_country: profile.birth_country,
      birth_state: profile.birth_state,
      residence_country: profile.residence_country,
      residence_state:
        profile.residence_state,
      high_level_education: profile.high_level_education,
    })

    if (error) {
      alert("Error updating profile");
      return;
    }

    await appContext.getMyProfile();
    // getUserSession()
    // alert("Successfully updated profile");
    navigate("/settings");
  }

  return (
    <div style={styles.profileScreen}>
      <div
        style={{
          width: appContext.SCREEN_WIDTH,
          // height: 1296,
          overflow: "auto",
          textAlign: "center",
          paddingTop: 40,
          paddingBottom: 20,
        }}
      >
        <div style={{ width: "90%", display: "inline-block" }}>
          <span
            style={{
              fontSize: 28,
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <img
              src="../../images/chevron_left.svg"
              style={{
                cursor: "pointer",
                width: 25,
                height: 25,
                marginTop: 7,
              }}
              alt=""
              onClick={() => navigate(-1)}
            />
            <p style={styles.header}>Profile</p>
            <img
              src="../../images/chevron_right.svg"
              style={{
                cursor: "pointer",
                width: 25,
                height: 25,
                marginTop: 7,
                visibility: "hidden",
              }}
              alt=""
            />
          </span>
          <p style={{ margin: "10px 0px 20px 0px" }}>
            Enter more information to help us understand your language environment. The fields with asterisks are required.
          </p>
          <form
            style={{ textAlign: "left", fontSize: 16 }}
            onSubmit={handleSubmit}
          >
            {/* Email */}
            <label>Email</label>
            <Input disabled={true} type="text" placeholder={authContext.user?.email} />
            {/* Birth Country */}
            <label className="required">Birth Country</label>
            <select
              style={styles.demographicsSelect}
              onChange={(e) =>
                setProfile({
                  ...profile,
                  birth_country:
                    e.target.value === "Select" ? null : e.target.value,
                })
              }
              value={profile?.birth_country}
            >
              <option>Select</option>
              {countries.map((country, i) => (
                <option key={i}>{country}</option>
              ))}
            </select>
            {/* Birth Province/State */}
            <label>Birth Province/State</label>
            <select
              style={styles.demographicsSelect}
              onChange={(e) => {
                setProfile({
                  ...profile,
                  birth_state:
                    e.target.value === "Select" ? null : e.target.value,
                });
              }}
              value={profile?.birth_state}
            >
              {profile?.birth_country === "Canada" ||
                profile?.birth_country ===
                "United States of America" ? (
                <option>Select</option>
              ) : (
                <option>Select a country of birth</option>
              )}
              {profile?.birth_country === "Canada" &&
                provinces.map((province, i) => (
                  <option key={i}>{province}</option>
                ))}
              {profile?.birth_country ===
                "United States of America" &&
                states.map((state, i) => <option key={i}>{state}</option>)}
            </select>
            {/* Country of Residence */}
            <label className="required">Country of Residence</label>
            <select
              style={styles.demographicsSelect}
              onChange={(e) => {
                setProfile({
                  ...profile,
                  residence_country:
                    e.target.value === "Select" ? null : e.target.value,
                });
              }}
              value={profile?.residence_country}
            >
              <option>Select</option>
              {countries.map((country, i) => (
                <option key={i}>{country}</option>
              ))}
            </select>
            {/* Province/State of Residence */}
            <label>Province/State of Residence</label>
            <select
              style={styles.demographicsSelect}
              onChange={(e) => {
                setProfile({
                  ...profile,
                  residence_state:
                    e.target.value === "Select" ? null : e.target.value,
                });
              }}
              value={profile?.residence_state}
            >
              {profile?.residence_country === "Canada" ||
                profile?.residence_country ===
                "United States of America" ? (
                <option>Select</option>
              ) : (
                <option>Select a country of residence</option>
              )}
              {profile?.residence_country === "Canada" &&
                provinces.map((province, i) => (
                  <option key={i}>{province}</option>
                ))}
              {profile?.residence_country ===
                "United States of America" &&
                states.map((state, i) => <option key={i}>{state}</option>)}
            </select>
            {/* Highest Level of Education */}
            <label className="required">Highest Level of Education</label>
            <select
              style={styles.demographicsSelect}
              onChange={(e) => {
                setProfile({
                  ...profile,
                  high_level_education:
                    e.target.value === "Select" ? null : e.target.value,
                });
              }}
              value={profile?.high_level_education}
            >
              <option>Select</option>
              {education.map((level, i) => (
                <option key={i}>{level}</option>
              ))}
            </select>
            <button style={styles.button} type="submit">
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

const INPUTHEIGHT = 54;
const BUTTONHEIGHT = 65;

const styles = {
  profileScreen: {
    // position: "fixed",
    padding: 0,
    margin: 0,
    // top: 0,
    // left: 0,
    width: "100vw",
    // height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "#F0F6FC",
    zIndex: 1,
  },
  header: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#000000",
  },
  demographicsSelect: {
    backgroundColor: colors.input,
    width: "100%",
    boxSizing: "border-box",
    height: INPUTHEIGHT,
    borderRadius: INPUTHEIGHT / 2,
    border: "none",
    textAlign: "left",
    fontSize: 18,
    color: colors.text,
    // color: "rgba(93, 100, 118, 0.5)",
    padding: "0px 22px 0px 22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 20px 0px",
  },
  button: {
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    width: "100%",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
    fontSize: 18,
    fontWeight: 600,
  },
};
