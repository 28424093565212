import { useNavigate } from "react-router-dom";
import colors from "../../config/colors";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

export default function PrivacyPolicyModal({ setShowPrivacyModal}) {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div
      style={{
        ...styles.modalScreen,
      }}
    >
      <div style={{
        ...styles.modal,
        width: Math.round(appContext.SCREEN_WIDTH * 0.907)
      }}>
        <div
          className="d-flex justify-content-between mb-20"
          style={{
            fontSize: 28,
          }}
        >
          <img
            src="./images/chevron_left.svg"
            style={{
              cursor: "pointer",
              width: 25,
              height: 25,
              marginTop: 7,
            }}
            alt=""
            onClick={() => {
              setShowPrivacyModal(false);
            }}
          />
        </div>
        {/* <p className="text-center" style={styles.header}>{t("About Us")}</p> */}
        <div className="modal-content font-bold mt-50">
          <p>
            Thank you for playing "Subjects and Objects”. This Privacy Policy explains how we collect, use, and protect your personal information when you use our game.
          </p>

          <p className="mt-20">Information We Collect:</p>
          <ul className="mt-0">
            <li>
              Email Addresses: We collect your email address for communication and user identification purposes only. We will not share your email address with third parties.
            </li>
            <li>
              Profile and Language Information: We collect profile information and language preferences for game-play-associated analytical purposes only. This information helps us improve the game and provide a better user experience.
            </li>
          </ul>

          <p className="mt-20">	How We Use Your Information:</p>
          <ul className="mt-0">
            <li>
              Email Addresses: We use your email address to communicate with you regarding game updates, notifications, and other relevant information.
            </li>
            <li>
              Profile and Language Information: We use your profile and language information to analyze game-play patterns and thought processes. This helps us improve the game.
            </li>
          </ul>

          <p className="mt-20">Data Security:</p>
          <ul className="mt-0">
            <li>
              We take the security of your personal information seriously and take measures to protect it from unauthorized access, disclosure, alteration, or destruction.
            </li>
            <li>
              We use encryption and other security measures to protect your data while it is being transmitted over the internet.
            </li>
          </ul>

          <p className="mt-20">Data Retention:</p>
          <ul className="mt-0">
            <li>
              We will retain your email address and profile information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
            </li>
          </ul>

          <p className="mt-20">Your Rights:</p>
          <ul className="mt-0">
            <li>
              You have the right to access, update, or delete your personal information at any time. You can do this by modifying the information in the Profile or Language areas.
            </li>
          </ul>

          <p className="mt-20">Changes to This Privacy Policy:</p>
          <ul className="mt-0">
            <li>
              We reserve the right to update or change this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on this page.
            </li>
          </ul>

          <p className="mt-20">Contact Us:</p>
          <ul className="mt-0">
            <li>
              If you have any questions about this Privacy Policy, please contact us at [subjects.objects.game@gmail.com].
            </li>
          </ul>

          <p className="mt-20">By using "Subjects and Objects," you agree to the terms of this Privacy Policy.</p>
        </div>
      </div>
    </div>
  );
}

const BUTTONHEIGHT = 65;

const styles = {
  modalScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(240, 246, 252, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  modal: {
    // display: "flex",
    // flexDirection: "column",
    justifyContent: "space-between",
    // padding: "40px 0 40px 0",
    height: "100%", 
    overflow: "auto", 
    padding: 40,
    backgroundColor: "#F0F6FC",
    border: "1px solid #BBD1E7",
    // borderRadius: 50,
    boxShadow: "0px 4px 25px rgba(64, 76, 85, 0.15)",
    // textAlign: "center",
    textAlign: "justify",
    textJustify: "inter-word",
  },
  header: {
    color: colors.header,
    fontSize: 36,
    fontWeight: "bold",
    marginBottom: 20,
  },
  button: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
};
