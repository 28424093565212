import colors from "../../config/colors";

function Input({
  id = "",
  type = "text",
  placeholder = "",
  handleOnChange,
  ref,
  disabled = false,
}) {
  return (
    <input
      id={id}
      type={type}
      style={styles.input}
      placeholder={placeholder}
      onChange={handleOnChange}
      disabled={disabled}
    />
  );
}

export default Input;

const INPUTHEIGHT = 54;

const styles = {
  input: {
    backgroundColor: colors.input,
    width: "100%",
    boxSizing: "border-box",
    height: INPUTHEIGHT,
    borderRadius: INPUTHEIGHT / 2,
    border: "none",
    textAlign: "left",
    fontSize: 18,
    color: colors.text,
    // color: "rgba(93, 100, 118, 0.5)",
    padding: "0px 22px 0px 22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 20px 0px",
  },
};
