// import { useNavigate } from "react-router-dom";
import colors from "../../config/colors";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

export default function AboutUsModal({ setShowAboutModal }) {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div
      style={{
        ...styles.modalScreen,
      }}
    >
      <div style={{
        ...styles.modal,
        width: Math.round(appContext.SCREEN_WIDTH * 0.907)
      }}>
        <div
          className="d-flex justify-content-between mb-20"
          style={{
            fontSize: 28,
          }}
        >
          <img
            src="./images/chevron_left.svg"
            style={{
              cursor: "pointer",
              width: 25,
              height: 25,
              marginTop: 7,
            }}
            alt=""
            onClick={() => {
              setShowAboutModal(false);
            }}
          />
        </div>
        {/* <p className="text-center" style={styles.header}>{t("About Us")}</p> */}
        <p className="font-bold mt-50">
          {t("aboutDescription")}
        </p>
      </div>
    </div>
  );
}

const BUTTONHEIGHT = 65;

const styles = {
  modalScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(240, 246, 252, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  modal: {
    // display: "flex",
    // flexDirection: "column",
    justifyContent: "space-between",
    // padding: "40px 0 40px 0",
    height: "100%",
    overflow: "auto",
    padding: 40,
    backgroundColor: "#F0F6FC",
    border: "1px solid #BBD1E7",
    // borderRadius: 50,
    boxShadow: "0px 4px 25px rgba(64, 76, 85, 0.15)",
    // textAlign: "center",
    textAlign: "justify",
    textJustify: "inter-word",
  },
  header: {
    color: colors.header,
    fontSize: 36,
    fontWeight: "bold",
    marginBottom: 20,
  },
  button: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
};
