// import { useNavigate } from "react-router-dom";
import colors from "../../config/colors";
import { useTranslation } from "react-i18next";
import "./HelpModal.scss"
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

export default function HelpModal({ showModal, setShowModal, path }) {
  // const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div
      style={{
        ...styles.modalScreen,
        visibility: showModal ? "visible" : "hidden",
      }}
    >
      <div style={{
        ...styles.modal,
        width: Math.round(appContext.SCREEN_WIDTH * 0.907)
      }}>
        <div
          className="d-flex justify-content-between mb-20"
          style={{
            fontSize: 28,
          }}
        >
          <img
            src="./images/chevron_left.svg"
            style={{
              cursor: "pointer",
              width: 25,
              height: 25,
              marginTop: 7,
            }}
            alt=""
            onClick={() => {
              // navigate("/");
              setShowModal(false);
            }}
          />
        </div>
        <p className="text-center" style={styles.header}>{t("How To Play")}</p>
        {path === "demo" && <div className="help-modal-content font-bold">
          <p>
            When the MAIN GAME SCREEN opens, you are presented with a question/statement and a square grid of images.You can enlarge each image to get a better look.
          </p>
          <p>
            For the question/statement 'Light-colored', words like 'fair', 'faint', 'cream', etc should come to mind. Take a moment to think of others.
          </p>
          <p>
            Like crossword clues, S&O tries to imply singular/plural and verb tenses in the clue.<br />
            *Enter your response in the textbox.
          </p>
          <p>
            For the grid, think of words that identify what the picture is or is about. You should see garlic, an onion, the moon, a paper clip, a horse,a nail, a spider, a calendar, and a bucket.
          </p>
          <p>
            Choose the image that you think relates best to the question/statement.<br />
            *You will see the correct answers in the Learning Moment.
          </p>
          <p>
            More difficult questions MAY have other related associations to consider and may have a hint. If you choose to use them, it will affect your score.
          </p>
        </div>}
        {path === "game" && <div className="help-modal-content font-bold">
          <p>
            When you open the main game screen, you'll see a question or statement along with a grid of images. You can click on each image to see it more clearly.
          </p>
          <p>
            For the question or statement, try to think of words that have a similar meaning, like you would in a crossword puzzle. The game also hints at whether the word should be singular or plural and its verb tense.
          </p>
          <p>
            In the grid of images, each image represents something. Your goal is to choose the image that you think best relates to the question or statement.  You will see the correct answers in the Learning Moment.
          </p>
          <p>
            Some questions might be more challenging and have additional related associations to consider. There may also be a hint available, but using it will impact your score.
          </p>
        </div>}
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <button
            style={{ ...styles.button, width: "100%" }}
            onClick={() => setShowModal(!showModal)}
          >
            {t("OK")}
          </button>
        </div>
      </div>
    </div>
  );
}

const BUTTONHEIGHT = 65;

const styles = {
  modalScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(240, 246, 252, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // padding: "40px 0 40px 0",
    height: "100%",
    overflow: "auto",
    padding: 40,
    backgroundColor: "#F0F6FC",
    border: "1px solid #BBD1E7",
    // borderRadius: 50,
    boxShadow: "0px 4px 25px rgba(64, 76, 85, 0.15)",
    // textAlign: "center",
    textAlign: "justify",
    textJustify: "inter-word",
  },
  header: {
    color: colors.header,
    fontSize: 36,
    fontWeight: "bold",
    marginBottom: 20,
  },
  button: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
};
