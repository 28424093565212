import { useState, useEffect, useContext } from "react";
import { LANGUAGE_OPTIONS } from "../../utils/data";
import { supabase } from "../../supabase";
import { useNavigate } from "react-router-dom";
import colors from "../../config/colors";
import { AppContext } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/Auth";

export default function Language({ user }) {
  const authContext = useAuth();
  const appContext = useContext(AppContext);
  const [language, setLanguage] = useState({});
  const [languageOptions, setLanguageOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLanguage(appContext.myLanguage);
  }, []);

  useEffect(() => {
    setLanguageOptions(LANGUAGE_OPTIONS);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    const { error } = await appContext.updateLanguage({
      home_language:
        language.home_language,
      work_language:
        language.work_language,
      other_language1:
        language.other_language1,
      other_language2:
        language.other_language2,
      other_language3:
        language.other_language3,
    })

    if (error) {
      alert("Error updating language");
      return;
    }

    await appContext.getMyLanguage();
    // getUserSession()
    // alert("Successfully updated language");
    navigate("/settings");
  }

  return (
    <div style={styles.languageScreen}>
      <div
        style={{
          width: appContext.SCREEN_WIDTH,
          // height: 1296,
          overflow: "auto",
          textAlign: "center",
          paddingTop: 40,
          paddingBottom: 20,
        }}
      >
        <div style={{ width: "90%", display: "inline-block" }}>
          <span
            style={{
              fontSize: 28,
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <img
              src="../../images/chevron_left.svg"
              style={{
                cursor: "pointer",
                width: 25,
                height: 25,
                marginTop: 7,
              }}
              alt=""
              onClick={() => navigate(-1)}
            />
            <p style={styles.header}>Language</p>
            <img
              src="../../images/chevron_right.svg"
              style={{
                cursor: "pointer",
                width: 25,
                height: 25,
                marginTop: 7,
                visibility: "hidden",
              }}
              alt=""
            />
          </span>
          <p style={{ margin: "10px 0px 20px 0px" }}>
            Enter more information to help us understand your language experience. The fields with asterisks are required.
          </p>
          <form
            style={{ textAlign: "left", fontSize: 16 }}
            onSubmit={handleSubmit}
          >
            {/* Languge Spoken at Home */}
            <label className="required">Language Spoken at Home</label>
            <select
              style={styles.languageSelect}
              onChange={(e) => {
                setLanguage({
                  ...language,
                  home_language:
                    e.target.value === "Select" ? null : e.target.value,
                });
              }}
              value={language.home_language}
            >
              <option>Select</option>
              {languageOptions.map((language, i) => (
                <option key={i}>{language}</option>
              ))}
            </select>
            {/* Language Spoken at School/Work */}
            <label className="required">Language Spoken at School/Work</label>
            <select
              style={styles.languageSelect}
              onChange={(e) => {
                setLanguage({
                  ...language,
                  work_language:
                    e.target.value === "Select" ? null : e.target.value,
                });
              }}
              value={
                language.work_language
              }
            >
              <option>Select</option>
              {languageOptions.map((language, i) => (
                <option key={i}>{language}</option>
              ))}
            </select>
            {/* Other Languages Spoken (1) */}
            <label>Other Languages Spoken (1)</label>
            <select
              style={styles.languageSelect}
              onChange={(e) => {
                setLanguage({
                  ...language,
                  other_language1:
                    e.target.value === "Select" ? null : e.target.value,
                });
              }}
              value={language.other_language1}
            >
              <option>Select</option>
              {languageOptions.map((language, i) => (
                <option key={i}>{language}</option>
              ))}
            </select>
            {/* Other Languages Spoken (2) */}
            <label>Other Languages Spoken (2)</label>
            <select
              style={styles.languageSelect}
              onChange={(e) => {
                setLanguage({
                  ...language,
                  other_language2:
                    e.target.value === "Select" ? null : e.target.value,
                });
              }}
              value={language.other_language2}
            >
              <option>Select</option>
              {languageOptions.map((language, i) => (
                <option key={i}>{language}</option>
              ))}
            </select>
            {/* Other Languages Spoken (3) */}
            <label>Other Languages Spoken (3)</label>
            <select
              style={styles.languageSelect}
              onChange={(e) => {
                setLanguage({
                  ...language,
                  other_language3:
                    e.target.value === "Select" ? null : e.target.value,
                });
              }}
              value={language.other_language3}
            >
              <option>Select</option>
              {languageOptions.map((language, i) => (
                <option key={i}>{language}</option>
              ))}
            </select>
            <button style={styles.button} type="submit">
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

const INPUTHEIGHT = 54;
const BUTTONHEIGHT = 65;

const styles = {
  languageScreen: {
    // position: "fixed",
    padding: 0,
    margin: 0,
    // top: 0,
    // left: 0,
    width: "100vw",
    // height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "#F0F6FC",
    zIndex: 1,
  },
  header: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#000000",
  },
  languageSelect: {
    backgroundColor: colors.input,
    width: "100%",
    boxSizing: "border-box",
    height: INPUTHEIGHT,
    borderRadius: INPUTHEIGHT / 2,
    border: "none",
    textAlign: "left",
    fontSize: 18,
    color: colors.text,
    // color: "rgba(93, 100, 118, 0.5)",
    padding: "0px 22px 0px 22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 20px 0px",
  },
  button: {
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    width: "100%",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
    fontSize: 18,
    fontWeight: 600,
  },
};
