import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import BackNavigation from '../BackNavigation/BackNavigation';

import "./ScoreBoard.scss"

export default function ScoreBoard({ user }) {
    const appContext = useContext(AppContext);

    return (
        <div className='scoreboard-container'>
            <div className='scoreboard-wrapper'>
                <BackNavigation />
                <div>
                    <p>Your Score is</p>
                </div>
                <div>
                    <p className='score-number'>{appContext.imageScore + appContext.textScore}</p>
                </div>
            </div>
        </div>
    )
}
