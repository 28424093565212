import colors from "../../config/colors";

function Error() {
  return (
    <div style={styles.errorScreen}>
      <p style={styles.header}>404 Not Found</p>
    </div>
  );
}

export default Error;

const styles = {
  errorScreen: {
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    color: colors.header,
    fontSize: 36,
    fontWeight: "bold",
    marginBottom: 20,
  },
};
