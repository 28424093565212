import { useState, useEffect, useCallback, useContext } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import * as xlsx from "xlsx";
import colors from "../../config/colors";
import PromptScreen from "../PromptScreen";
import ResultsModal from "../ResultsModal/ResultsModal";
import Grid from "../Grid/Grid";
// import Timer from "../Timer";
// import HintButton from "../HintButton";
import Navbar from "../Navbar";
// import { LongPressEventType, useLongPress } from "use-long-press";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../contexts/AppContext";
import ImageModal from "../ImageModal/ImageModal";

const CDN_PUZZLES_URL =
  process.env.REACT_APP_SUPABASE_URL + "/storage/v1/object/public/puzzles/";
const CDN_IMAGES_URL =
  process.env.REACT_APP_SUPABASE_URL + "/storage/v1/object/public/images/";

function DemoScreen() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { grid } = useParams();
  const [rows, setRows] = useState(0);
  const [columns, setColumns] = useState(0);
  const [images, setImages] = useState([]);
  const [questions, setQuestions] = useState([{ Question: "" }]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(undefined);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [enabled, setEnabled] = useState(true);
  const [longPressed, setLongPressed] = useState(false);
  const [showSelectedModal, setShowSelectedModal] = useState(false);
  const [answer, setAnswer] = useState("");
  const [answerScore, setAnswerScore] = useState(0);
  const [imageScore, setImageScore] = useState(0);
  const [magnifiedImage, setMagnifiedImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let PUZZLE_URL = CDN_PUZZLES_URL + "DEMOGAMEG-ENG_G0301.xlsx";

      const data = await (await fetch(PUZZLE_URL)).arrayBuffer();
      /* data is an ArrayBuffer */
      const workbook = xlsx.read(data); // xlsx.read(data, { type: "binary" })
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);

      let questions = json.map((item, i) => {
        let Question = item["Question"];
        let Question_Seq = item["Question_Seq"];
        let L_Listing = item["L_Listing"];
        let R_Listing = item["R_Listing"];
        let L_TXT00 = item["L_TXT00"];
        let R_Drive_Dir_File = item["R_Drive_Dir_File"];
        let Exact = item["Exact"];
        let L_Plural_TXT00 = item["L_Plural_TXT00"];
        let L_Alt_TXT00 = item["L_Alt_TXT00"];
        let answer = i;

        return {
          Question,
          Question_Seq,
          L_Listing,
          R_Listing,
          L_TXT00,
          R_Drive_Dir_File,
          answer, 
          Exact, 
          L_Plural_TXT00, 
          L_Alt_TXT00
        };
      });

      questions = questions.filter(
        (item) => item["Question"] !== "" && item["Question_Seq"] !== undefined
      );

      questions = questions.sort((a, b) => a.Question_Seq - b.Question_Seq);

      let Grid_Size = json[0]["Grid_Size"];
      let [rows, columns] = Grid_Size.split("x");
      let coordinates = json.map((item) => item["X"] + "" + item["Y"]);

      setRows(rows);
      setColumns(columns);
      setImages(json);
      setQuestions(questions);
      setShowPrompt(true);
      setCoordinates(coordinates);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (questions.length === 0) {
      setGameOver(true);
    }
  }, [questions]);

  function handleSelectedImage(imageData) {
    if (!questions.length) return; // prevent user from selecting image if there are no questions
    setSelectedImage(
      JSON.stringify(imageData) === JSON.stringify(selectedImage)
        ? undefined
        : imageData
    );
  }

  async function handleAnswer() {
    await appContext.updateDemoDailyPlays();
    updateScore();
    setAnswer("");
    // let unshiftedQuestions = [...questions.slice(1)];
    // setQuestions(unshiftedQuestions);
    handleConfirmSelection();
    // setSelectedImage(undefined);
    setShowSelectedModal(false);
  }

  const updateScore = () => {
    let currentQuestion = questions[0];
    const exact = currentQuestion.Exact;
    const isTxtCorrect = exact ? currentQuestion.L_TXT00 === answer :
    (currentQuestion.L_TXT00.toLowerCase() === answer.toLowerCase().trim() ||
      (!!currentQuestion.L_Alt_TXT00 ? currentQuestion.L_Alt_TXT00.toLowerCase() === answer.toLowerCase().trim() : false) ||
      (!!currentQuestion.L_Plural_TXT00 ? currentQuestion.L_Plural_TXT00.toLowerCase() === answer.toLowerCase().trim() : false));

    if (currentQuestion.R_Drive_Dir_File === selectedImage.R_Drive_Dir_File) {
      setImageScore(1000);
    }

    if (isTxtCorrect) {
      setAnswerScore(1000);
    }
  }

  function handleConfirmSelection() {
    let imageIndex = coordinates.indexOf(
      selectedImage["X"] + "" + selectedImage["Y"]
    );

    let correctIndex = questions[0]["answer"];

    if (imageIndex === correctIndex) {
      setCorrectAnswer(true);
      handleShowModal(true);
    } else {
      setCorrectAnswer(false);
      handleShowModal(true);
    }
  }

  function handleShowModal(bool) {
    setShowModal(bool);
  }

  function redirectUser() {
    if (gameOver) {
      return <Navigate to="/" />;
    }
  }

  function redirectUnauthorizedUser() {
    let credentials = localStorage.getItem("credentials");
    if (!credentials) {
      return <Navigate to="/signin" />;
    }
  }

  // const callback = useCallback((event) => {
  //   // event is optional
  //   // alert('Long pressed!');
  //   setLongPressed(true);
  // }, []);

  // const bind = useLongPress(enabled ? callback : null, {
  //   // onStart: (event, meta) => {
  //   //   // console.log("Press started", meta);
  //   // },
  //   // onFinish: (event, meta) => {
  //   //   // console.log("Long press finished", meta);
  //   // },
  //   // onCancel: (event, meta) => {
  //   //   // console.log("Press cancelled", meta);
  //   // },
  //   // onMove: () => console.log("Detected mouse or touch movement"),
  //   filterEvents: (event) => true, // All events can potentially trigger long press
  //   threshold: 1000, // milliseconds
  //   captureEvent: true, // if true, prevent event from clearing after React processes it
  //   cancelOnMovement: false, // square size (px) inside which movement won't canel long press
  //   cancelOutsideElement: true, // cancels long press when mouse goes outside element
  //   detect: LongPressEventType.Pointer,
  // });

  return (
    <div className="pt-30" style={styles.DemoScreen}>
      {/* {redirectUnauthorizedUser()} */}
      {/* {redirectUser()} */}
      {questions.length > 0 && (
        <PromptScreen
          phrase={questions[0]["Question"]}
          showPrompt={showPrompt}
          setShowPrompt={setShowPrompt}
          questionNumber={questionNumber}
        />
      )}
      {!!magnifiedImage && <ImageModal
        longPressed={longPressed}
        magnifiedImage={magnifiedImage}
        setLongPressed={setLongPressed}
      />}
      <ResultsModal
        showModal={showModal}
        selectedImage={selectedImage}
        correctImage={
          questions.length ? questions[0]["R_Drive_Dir_File"] : null
        }
        correctAnswer={correctAnswer}
        handleShowModal={handleShowModal}
        handleCycleQuestions={() => navigate("/")}
        question={questions[0]}
        score={imageScore + answerScore}
      />
      <div
        className="d-flex justify-content-between mt-10 mb-10"
        style={{
          width: '100%',
          maxWidth: appContext.SCREEN_WIDTH,
          paddingLeft: 26,
          paddingRight: 26
        }}
      >
        <img
          src="./images/chevron_left.svg"
          style={{
            cursor: "pointer",
            width: 25,
            height: 25,
            marginTop: 7,
          }}
          alt=""
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <p style={{ fontSize: 23, marginBottom: 10 }}>{t("Phrase")}</p>
      {questions.length > 0 && (
        <p style={{
          ...styles.phrase,
          maxWidth: appContext.SCREEN_WIDTH
        }}>{t(questions[0]["Question"])}</p>
      )}
      <input
        style={styles.input}
        type="text"
        placeholder={`${t("Type your answer")}...`}
        onChange={(e) => setAnswer(e.target.value)}
        value={answer}
      />
      {/* <div className="mt-10 mb-10 font-bold text-center">{t("Click/hold to enlarge image for better look")}</div> */}
      <Grid
        rows={rows}
        columns={columns}
        images={images}
        selectedImage={selectedImage}
        handleSelectedImage={handleSelectedImage}
        coordinates={coordinates}
        setLongPressed={setLongPressed}
        setMagnifiedImage={setMagnifiedImage}
      />
      {/* <Timer /> */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0px 20px 0px",
        }}
      >
        <HintButton hintNumber={1} />
        <HintButton hintNumber={2} />
      </div> */}
      <div
        style={{
          margin: "20px 0px",
        }}
      >
        <button
          onClick={handleAnswer}
          style={{
            ...(selectedImage === undefined || answer == ""
              ? styles.disabledButton
              : styles.button),
            width: Math.round(appContext.SCREEN_WIDTH * 0.907),
          }}
          disabled={!questions.length || selectedImage === undefined || answer == ""}
        >
          {t("Confirm Selection")}
        </button>
      </div>
    </div>
  );
}

export default DemoScreen;

const BUTTONHEIGHT = 65;

const styles = {
  DemoScreen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    // height: "100vh",
  },
  phrase: {
    fontSize: 36,
    fontWeight: "bold",
    color: colors.phrase,
    marginBottom: 25,
    textAlign: "center"
  },
  button: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
  disabledButton: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: colors.disabledButton,
    color: colors.buttonText,
    cursor: "not-allowed",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
  modalScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(240, 246, 252, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  input: {
    backgroundColor: colors.input,
    maxWidth: 378,
    width: "90%",
    boxSizing: "border-box",
    height: 54,
    borderRadius: 27,
    border: "none",
    textAlign: "left",
    fontSize: 18,
    color: colors.text,
    // color: "rgba(93, 100, 118, 0.5)",
    padding: "0px 22px 0px 22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: "10px 0px 20px 0px",
    marginBottom: 20,
  },
};
