import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
const humanizeDuration = require("humanize-duration");

// TODO: - move to utilities
const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});
// TODO: - move to utilities
const thousandSeparator = (n) => {
  n = String(n).split("");
  let output = [];
  for (let i = n.length - 1, j = 1; i >= 0; i--, j++) {
    output.unshift(n[i]);
    if (j % 3 === 0 && j < n.length && n[i] !== ".") {
      output.unshift(",");
    }
  }
  return output.join("");
};

export default function Statistics({ user }) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  function getMultiplier(referrals) {
    return 1 + referrals / 10;
  }

  return (
    <div style={styles.statisticsScreen}>
      <div style={{
        width: appContext.SCREEN_WIDTH,
        textAlign: "center",
        paddingTop: 40
      }}>
        <div style={{ width: "90%", display: "inline-block" }}>
          <span
            style={{
              fontSize: 28,
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 0px",
            }}
          >
            <img
              src="../../images/chevron_left.svg"
              style={{
                cursor: "pointer",
                width: 25,
                height: 25,
                marginTop: 7,
              }}
              alt=""
              onClick={() => navigate(-1)}
            />
            <p style={styles.header}>Statistics</p>
            <img
              src="../../images/chevron_right.svg"
              style={{
                cursor: "pointer",
                width: 25,
                height: 25,
                marginTop: 7,
                visibility: "hidden",
              }}
              alt=""
            />
          </span>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              color: "#5D6476",
              padding: 20,
              borderRadius: 25,
            }}
          >
            <span style={styles.row}>
              <p className="text-start">Total Points</p>
              <p style={{ ...styles.stat, color: "#4782FA" }}>
                {thousandSeparator(
                  appContext.imageScore + appContext.textScore
                )}{" "}
                pts
              </p>
            </span>
          </div>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              color: "#5D6476",
              padding: 20,
              borderRadius: 25,
              margin: "20px 0px",
            }}
          >
            <span style={{ ...styles.row, marginBottom: 20 }}>
              <p className="text-start">Total Questions</p>
              <p style={styles.stat}>
                {appContext.questionsCount}
              </p>
            </span>
            <span style={{ ...styles.row, marginBottom: 20 }}>
              <p className="text-start">Total Correct Questions</p>
              <p style={styles.stat}>
                {appContext.correctCount}
              </p>
            </span>
            <span style={{ ...styles.row, marginBottom: 20 }}>
              <p className="text-start">Total Partially Correct Questions (text)</p>
              <p style={styles.stat}>
                {appContext.textOnlyCorrectCount}
              </p>
            </span>
            <span style={{ ...styles.row, marginBottom: 20 }}>
              <p className="text-start">Total Partially Correct Questions (image)</p>
              <p style={styles.stat}>
                {appContext.imageOnlyCorrectCount}
              </p>
            </span>
            <span style={{ ...styles.row, marginBottom: 20 }}>
              <p className="text-start">Total Incorrect Questions</p>
              <p style={styles.stat}>
                {appContext.incorrectCount}
              </p>
            </span>
            {/* <span style={styles.row}>
              <p>Hints Used</p>
              <p style={styles.stat}>
                {userData.user_metadata.statistics.hintsUsed}
              </p>
            </span> */}
          </div>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              color: "#5D6476",
              padding: 20,
              borderRadius: 25,
              margin: "20px 0px",
            }}
          >
            <span style={{ ...styles.row, marginBottom: 20 }}>
              <p className="text-start">Referrals</p>
              <p style={styles.stat}>
                {appContext.inviteCount}
              </p>
            </span>
            <span style={styles.row}>
              <p className="text-start">Consecutive Days</p>
              <p style={styles.stat}>
                {appContext.consecutiveDays}
              </p>
            </span>
          </div>
          {/* <div
            style={{
              backgroundColor: "#FFFFFF",
              color: "#5D6476",
              padding: 20,
              borderRadius: 25,
            }}
          >
            <span style={styles.row}>
              <p>Total Time Played</p>
              <p style={styles.stat}>
                {shortEnglishHumanizer(
                  userData.user_metadata.statistics.totalTimePlayed
                )
                  .replace(" ", "")
                  .replace(",", "")}
              </p>
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
}

const styles = {
  statisticsScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "#F0F6FC",
    zIndex: 1,
  },
  header: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#000000",
  },
  row: {
    // margin: "5px 0px",
    display: "flex",
    justifyContent: "space-between",
  },
  stat: {
    fontWeight: "bold",
    color: "#171D2E",
  },
};
