import { t } from "i18next";
import colors from "../../config/colors";
import "./ResultsModal.scss";
import { useState, useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import { useNavigate } from "react-router-dom";

const CDN_IMAGES_URL =
  process.env.REACT_APP_SUPABASE_URL + "/storage/v1/object/public/images/";

function ResultsModal({
  showModal,
  selectedImage,
  correctImage,
  correctAnswer,
  hints = 0,
  handleCycleQuestions,
  question,
  score,
  alreadyPlayed = false,
}) {
  const [selectedNaturalWidth, setSelectedNaturalWidth] = useState(0);
  const [selectedNaturalHeight, setSelectedNaturalHeight] = useState(0);
  const [correctNaturalWidth, setCorrectNaturalWidth] = useState(0);
  const [correctNaturalHeight, setCorrectNaturalHeight] = useState(0);

  const appContext = useContext(AppContext);
  // const navigate = useNavigate();
  let imageUrl;
  let L_Listing = "";
  let R_Listing = "";
  let Reason = "";

  if (correctImage) {
    imageUrl = correctImage;
  }

  if (question && question.L_Listing) {
    L_Listing = question.L_Listing;
  }

  if (question && question.R_Listing) {
    R_Listing = question.R_Listing;
  }

  if (question && question.Reason) {
    Reason = question.Reason;
  }

  const handleSelectedLoad = (e) => {
    setSelectedNaturalWidth(e.target.naturalWidth);
    setSelectedNaturalHeight(e.target.naturalHeight);
  }

  const handleCorrectLoad = (e) => {
    setCorrectNaturalWidth(e.target.naturalWidth);
    setCorrectNaturalHeight(e.target.naturalHeight);
  }

  return (
    <div
      className="modal-container"
      style={{
        ...styles.modalScreen,
        visibility: showModal && correctImage ? "visible" : "hidden",
      }}
    >
      <div style={{
        ...styles.modal,
        width: Math.round(appContext.SCREEN_WIDTH * 0.907)
      }}>
        <p className="text-center" style={styles.header}>Learning Moment</p>
        {alreadyPlayed &&
          <p className="font-bold mb-20 text-danger">Game has already been played.</p>}
        <div style={{
          padding: "0px 40px"
        }}
          className="mb-10"
        >{L_Listing}</div>
        <div style={{
          padding: "0px 40px"
        }}
          className="mb-10 font-bold"
        >{t("Reason")}: {t(Reason)}</div>
        <div className="image-container">
          <div style={{ textAlign: "center", margin: "0 20px 10px 0" }}>
            <div className="d-flex align-items-center justify-content-center image-wrapper"
              style={{
                width: 130,
                height: 130
              }}>
              <img
                onLoad={handleSelectedLoad}
                style={{
                  ...styles.cell,
                  width: selectedNaturalWidth >= selectedNaturalHeight ? 130 : "auto",
                  height: selectedNaturalWidth <= selectedNaturalHeight ? 130 : "auto",
                }}
                src={
                  selectedImage ? CDN_IMAGES_URL + selectedImage.R_Path_Drive_Folder_File.substring(45) : "/images/default_image.svg"
                }
                alt=""
              />
            </div>
            <p
              style={{
                ...styles.result,
                color: correctAnswer ? colors.success : colors.error,
              }}
            >
              {t("Selected")}
            </p>
          </div>
          <div style={{ textAlign: "center", margin: "0 0 10px 0" }}>
            <div className="d-flex align-items-center justify-content-center image-wrapper"
              style={{
                width: 130,
                height: 130
              }}>
              <img
                onLoad={handleCorrectLoad}
                style={{
                  ...styles.cell,
                  width: correctNaturalWidth >= correctNaturalHeight ? 130 : "auto",
                  height: correctNaturalWidth <= correctNaturalHeight ? 130 : "auto",
                }}
                src={
                  imageUrl ? CDN_IMAGES_URL + imageUrl : "/images/default_image.svg"
                }
                alt=""
              />
            </div>
            <p
              style={{
                ...styles.result,
                color: colors.success,
              }}
            >
              {t("Correct")}
            </p>
          </div>
        </div>
        <div style={{ fontSize: 28, marginBottom: 10 }}>
          <span style={{
            color: score > 1000 ? colors.success : colors.error,
            fontWeight: "bold"
          }}>
            {score > 0 ? "+" : ""}
            {score}
          </span>{" "}
          {t("pts")}
        </div>
        <div style={{ padding: "0px 40px" }}>{R_Listing}</div>
        <div style={{ textAlign: "center", margin: "10px 0px" }}>
          {hints > 0
            ? hints === 1
              ? t(`You used 1 hint.`)
              : `${t("You used")} ${hints} ${t("hints")}.`
            : null}
        </div>
        <div style={{ paddingLeft: 40, paddingRight: 40, display: "flex" }}>
          {/* <div style={{ width: "50%", paddingRight: 5 }}>
            <button
              style={{
                ...styles.button,
                backgroundColor: "#5D6476",
                width: "100%",
              }}
            >
              Stats
            </button>
          </div> */}
          <div style={{ width: "100%", paddingLeft: 5 }}>
            <button
              style={{ ...styles.button, width: "100%" }}
              onClick={handleCycleQuestions}
            >
              {t("Continue")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultsModal;

const BUTTONHEIGHT = 59;

const styles = {
  modalScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(240, 246, 252, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "40px 0 40px 0",
    backgroundColor: "#F0F6FC",
    border: "1px solid #BBD1E7",
    borderRadius: 50,
    boxShadow: "0px 4px 25px rgba(64, 76, 85, 0.15)",
    textAlign: "center",
  },
  cell: {
    // maxWidth: 130,
    // maxHeight: 130,
    // width: "auto",
    // height: "auto",
    // width: 130,
    // height: 130,
    backgroundColor: "#C2DCBB",
    // borderRadius: 10,
    objectFit: "cover",
    // display: "block",
  },
  result: {
    fontSize: 36,
    fontWeight: "bold",
  },
  divider: {
    border: "1px solid #BBD1E7",
    width: 130,
  },
  button: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
  header: {
    color: colors.header,
    fontSize: 36,
    fontWeight: "bold",
    marginBottom: 20,
  },
};
