import { useState, useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import colors from "../../config/colors";
import "./Grid.scss";
const CDN_IMAGES_URL =
  process.env.REACT_APP_SUPABASE_URL + "/storage/v1/object/public/images/";

function Grid({
  rows,
  columns,
  images,
  selectedImage,
  handleSelectedImage,
  coordinates,
  setLongPressed,
  setMagnifiedImage
}) {
  const appContext = useContext(AppContext);
  const screenWidth = appContext.SCREEN_WIDTH - 10;
  let gridArea = rows * columns;
  let emptyGrid = Array.from(Array(gridArea).keys());

  return (
    <div
      style={{
        ...styles.grid,
        gridTemplateRows: "auto ".repeat(rows),
        gridTemplateColumns: "auto ".repeat(columns),
      }}
    >
      {images.length > 0
        ? images.map((imageData, i) => (
          <Square
            key={i}
            index={i}
            rows={rows}
            imageData={imageData}
            selectedImage={selectedImage}
            handleSelectedImage={handleSelectedImage}
            coordinates={coordinates}
            screenWidth={screenWidth}
            setLongPressed={setLongPressed}
            setMagnifiedImage={setMagnifiedImage}
          />
        ))
        : emptyGrid.map((i) => <Square key={i} index={i} />)}
    </div>
  );
}

function Square({
  index,
  rows,
  imageData = {},
  selectedImage,
  handleSelectedImage,
  coordinates,
  screenWidth,
  setLongPressed,
  setMagnifiedImage
}) {
  const [naturalWidth, setNaturalWidth] = useState(0);
  const [naturalHeight, setNaturalHeight] = useState(0);

  const handleClick = (e) => {
    if (Object.entries(imageData).length > 0) {
      handleSelectedImage(imageData);
    }
  }

  const handleImageLoad = (e) => {
    setNaturalWidth(e.target.naturalWidth);
    setNaturalHeight(e.target.naturalHeight);
  }

  let imageUrl = "/images/default_image.svg";

  if (Object.entries(imageData).length > 0) {
    let { R_Path_Drive_Folder_File } = imageData;
    R_Path_Drive_Folder_File = R_Path_Drive_Folder_File.substring(45);
    imageUrl = CDN_IMAGES_URL + R_Path_Drive_Folder_File;
  }

  let imageIndex = undefined;

  if (selectedImage !== undefined) {
    imageIndex = coordinates.indexOf(
      selectedImage["X"] + "" + selectedImage["Y"]
    );
  }

  return (
    <div className="square-wrapper">
      <div className="magnifier-wrapper"
        onClick={(e) => {
          e.stopPropagation();
          setMagnifiedImage(imageData);
          setLongPressed(true);
        }}
      >
        <svg fill="#ffffff" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.4 490.4">
          <g>
            <path d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796
		s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z
		 M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z"/>
          </g>
        </svg>
      </div>
      <div
        className="image-wrapper"
        style={{
          width: (screenWidth - 10 * rows) / rows,
          height: (screenWidth - 10 * rows) / rows,
          opacity: imageIndex === undefined || imageIndex === index ? 1 : 0.35,
          outline:
            imageIndex === index ? `6px solid ${colors.highlight}` : "none",
          outlineOffset: imageIndex === index ? -6 : 0,
        }}
      >
        <img
          style={{
            ...styles.square,
            objectFit: "fill",
            width: naturalWidth >= naturalHeight ? (screenWidth - 10 * rows) / rows : "auto",
            height: naturalWidth <= naturalHeight ? (screenWidth - 10 * rows) / rows : "auto",
          }}
          onLoad={handleImageLoad}
          src={imageUrl}
          alt=""
          onClick={handleClick}
        />
      </div>
    </div>
  );
}

export default Grid;

const styles = {
  grid: {
    display: "grid",
    columnGap: "10px",
    rowGap: "10px",
    // backgroundColor: "darkmagenta",
    // marginBottom: 30,
  },
  square: {
    // width: "130px",
    // height: "130px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#C2DCBB",
    // borderRadius: "5px",
    objectFit: "cover",
    cursor: "pointer",
  },
};
