const colors = {
  bgLight: "#F0F6FC",
  bgDark: "#5D6476",
  text: "#5D6476",
  phrase: "#4782FA",
  button: "#4782FA",
  highlight: "#4782FA",
  link: "#4782FA",
  secondaryButton: "#FFB800",
  disabledButton: "rgba(93, 100, 118, 0.3)",
  settingsButton: "#FFFFFF",
  buttonText: "#FFFFFF",
  header: "#171D2E",
  success: "#75D875",
  error: "#F94646",
  cell: "#C2DCBB",
  input: "#FFFFFF",
};

export default colors;
