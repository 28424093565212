import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import "./TryAgainModal.scss";

function TryAgainModal({
  isTextCorrect = false,
  isImageCorrect = false,
  handleTryAgain,
}) {
  const appContext = useContext(AppContext);

  return (
    <div
      className="modal-container"
    >
      <div
        className="modal-wrapper"
        style={{
          width: Math.round(appContext.SCREEN_WIDTH * 0.907)
        }}>
        {!isTextCorrect && isImageCorrect && <p className="text-center">
          You have selected the correct image, but your text answer is incorrect.
        </p>}
        {isTextCorrect && !isImageCorrect && <p className="text-center">
          You have selected the wrong image, but your text answer is correct.
        </p>}
        {!isTextCorrect && !isImageCorrect && <p className="text-center">
          Both your image and text answer are incorrect.
        </p>}
        <div className="mt-20" style={{ display: "flex" }}>
          <div style={{ width: "100%", paddingLeft: 5 }}>
            <button
              className="button"
              style={{ width: "100%" }}
              onClick={handleTryAgain}
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TryAgainModal;