import { useContext, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import colors from "../../config/colors";
import Button from "../Button";
import { useAuth } from "../../contexts/Auth";
import "./SignIn.scss";
import { AppContext } from "../../contexts/AppContext";

function SignIn({ user }) {
  const appContext = useContext(AppContext);
  // const { user } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Get signUp function from the auth context
  const { signIn } = useAuth();

  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    // Calls "signIn" function from the context
    const { error } = await signIn({ email, password });

    if (error) {
      alert("error signing in");
    } else {
      // Redirect user to Dashboard
      localStorage.setItem("welcomeShown", "false");
      localStorage.setItem("leaderboardShown", "false");
      navigate("/");
    }
  }

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <form style={styles.signInScreen} onSubmit={handleSubmit}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: Math.round(appContext.SCREEN_WIDTH * 0.907),
          // height: 932,
          height: "100vh",
          textAlign: "center",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div className="logo-wrapper">
          <img 
            alt="Logo" 
            src="/images/logo_inverse.png" 
            onClick={() => navigate("/")}
          />
        </div>
        <div>
          <p style={styles.header}>Sign In</p>
          <input
            style={styles.input}
            id="email"
            type="email"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            style={styles.input}
            id="password"
            type="password"
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" buttonText="Sign In" />
          <Link to="/reset-password" style={styles.forgotPasswordLink}>
            Forgot your password?
          </Link>
        </div>
        <div style={{ paddingBottom: 60 }}>
          <p style={{ fontSize: 24 }}>
            Don’t have an account?{" "}
            <Link style={styles.link} to="/signup">
              Sign Up
            </Link>
          </p>
          <div style={{ color: "rgba(93, 100, 118, 0.4)", marginTop: 10 }}>
            <p>By continuing you provide an agreement to our</p>
            <p>
              <a
                style={styles.secondaryLink}
                href="https://sno-app.webflow.io/"
              >
                Terms of Services
              </a>{" "}
              and{" "}
              <a
                style={styles.secondaryLink}
                href="https://sno-app.webflow.io/"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </form>
  );
}

export default SignIn;

const INPUTHEIGHT = 54;

const styles = {
  signInScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#F0F6FC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  header: {
    color: colors.header,
    fontSize: 36,
    fontWeight: "bold",
  },
  input: {
    backgroundColor: colors.input,
    width: "100%",
    boxSizing: "border-box",
    height: INPUTHEIGHT,
    borderRadius: INPUTHEIGHT / 2,
    border: "none",
    textAlign: "left",
    fontSize: 18,
    color: colors.text,
    padding: "0px 22px 0px 22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 10px 0px",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
    color: colors.highlight,
  },
  secondaryLink: {
    textDecoration: "underline",
    fontWeight: "bold",
    color: "rgba(93, 100, 118, 0.4)",
  },
  forgotPasswordLink: {
    textDecoration: "none",
    fontWeight: "bold",
    color: colors.highlight,
    marginTop: 10, 
    display: "block", 
  },
};
