import { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";

function UpgradeScreen() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <div style={styles.upgradeScreen}>
      <div style={{ width: appContext.SCREEN_WIDTH, textAlign: "center", paddingTop: 40 }}>
        <div style={{ width: "90%", display: "inline-block" }}>
          <span
            style={{
              fontSize: 28,
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 0px",
            }}
          >
            <img
              src="./images/chevron_left.svg"
              style={{
                cursor: "pointer",
                width: 25,
                height: 25,
                marginTop: 7,
              }}
              alt=""
              onClick={() => navigate(-1)}
            />
            <p style={styles.header}>Upgrades</p>
            <img
              src="./images/chevron_right.svg"
              style={{
                cursor: "pointer",
                width: 25,
                height: 25,
                marginTop: 7,
                visibility: "hidden",
              }}
              alt=""
            />
          </span>
          {/* <div
            style={{
              backgroundColor: "#DAE4EE",
              color: "#000000",
              padding: 20,
              borderRadius: 50,
              margin: "20px 0px",
            }}
          >
            <p
              style={{
                fontSize: 20,
                fontWeight: "bold",
                marginTop: 10,
                marginBottom: 15,
                letterSpacing: "4px",
              }}
            >
              PREVIOUS DAY PUZZLES
            </p>
            <p style={{ margin: "5px 0px" }}>
              Sign In or Sign Up to access puzzles from the previous 14 days.
            </p>
            <Link to="/signin">
              <button
                style={{
                  width: "100%",
                  height: 59,
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  fontSize: 18,
                  fontWeight: "bold",
                  border: "none",
                  borderRadius: 50,
                  marginTop: 10,
                  cursor: "pointer",
                }}
              >
                Take me there!
              </button>
            </Link>
          </div> */}
          <div
            style={{
              backgroundColor: "#DAE4EE",
              color: "#000000",
              padding: 20,
              borderRadius: 50,
              margin: "20px 0px",
            }}
          >
            <p
              style={{
                fontSize: 20,
                fontWeight: "bold",
                marginTop: 10,
                marginBottom: 15,
                letterSpacing: "4px",
              }}
            >
              Free Upgrade to 4 X 4 Game
            </p>
            <p style={{ margin: "5px 0px" }}>
              Play 1 Day consecutively to unlock 4X4 Game.
            </p>
            <Link to="/upgrades">
              <button
                style={{
                  width: "100%",
                  height: 59,
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  fontSize: 18,
                  fontWeight: "bold",
                  border: "none",
                  borderRadius: 50,
                  marginTop: 10,
                  cursor: "pointer",
                }}
              >
                Take me there!
              </button>
            </Link>
          </div>
          <div
            style={{
              backgroundColor: "#DAE4EE",
              color: "#000000",
              padding: 20,
              borderRadius: 50,
              margin: "20px 0px",
            }}
          >
            <p
              style={{
                fontSize: 20,
                fontWeight: "bold",
                marginTop: 10,
                marginBottom: 15,
                letterSpacing: "4px",
              }}
            >
              Free Upgrade to 3x3 Game (3Q) and 4x4 Game (4Q)
            </p>
            <p style={{ margin: "5px 0px" }}>
              Play 2 days consecutively to unlock the 3X3 Game (3Q). Play 3 days consecutively and unlock the 4X4 Game (4Q). Start again if a day is missed.
            </p>
            <Link to="/upgrades">
              <button
                style={{
                  width: "100%",
                  height: 59,
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  fontSize: 18,
                  fontWeight: "bold",
                  border: "none",
                  borderRadius: 50,
                  marginTop: 10,
                  cursor: "pointer",
                }}
              >
                Take me there!
              </button>
            </Link>
          </div>
          <div
            style={{
              backgroundColor: "#DAE4EE",
              color: "#000000",
              padding: 20,
              borderRadius: 50,
              margin: "20px 0px",
            }}
          >
            <p
              style={{
                fontSize: 20,
                fontWeight: "bold",
                marginTop: 10,
                marginBottom: 15,
                letterSpacing: "4px",
              }}
            >
              Paid Upgrade to 5x5 Game (5Q) and 6x6 Game (6Q)
            </p>
            <p style={{ margin: "5px 0px" }}>
              Coming Soon.
            </p>
            <Link to="/upgrades">
              <button
                style={{
                  width: "100%",
                  height: 59,
                  backgroundColor: "#eeeeee",
                  color: "#000000",
                  fontSize: 18,
                  fontWeight: "bold",
                  border: "none",
                  borderRadius: 50,
                  marginTop: 10,
                  cursor: "pointer",
                }}
                disabled
              >
                Take me there!
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradeScreen;

const styles = {
  upgradeScreen: {
    // position: "fixed",
    padding: 0,
    margin: 0,
    // top: 0,
    // left: 0,
    width: "100vw",
    // height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "#F0F6FC",
    // zIndex: 2,
  },
  header: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#000000",
  },
};
