import { useAuth } from "./Auth";
import { useEffect, useState, createContext } from "react";
import { supabase } from "../supabase";
import moment from "moment";

export const AppContext = createContext();

export function AppProvider({ children }) {
  const { user } = useAuth();
  const cacheValues = JSON.parse(
    localStorage.getItem("subjects-objects-context")
  );
  const [consecutiveDays, setConsecutiveDays] = useState(0);
  const [imageScore, setImageScore] = useState(0);
  const [textScore, setTextScore] = useState(0);
  const [questionsCount, setQuestionsCount] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [imageOnlyCorrectCount, setImageOnlyCorrectCount] = useState(0);
  const [textOnlyCorrectCount, setTextOnlytCorrectCount] = useState(0);
  const [inviteCount, setInviteCount] = useState(0);
  const [puzzleMap, setPuzzleMap] = useState({});
  const [playedCountsList, setPlayedCountsList] = useState(
    cacheValues ? cacheValues.playedCountsList : null
  );
  const [previousDays, setPreviousDays] = useState(
    cacheValues ? cacheValues.previousDays : 0
  );
  const [myProfile, setMyProfile] = useState({});
  const [myLanguage, setMyLanguage] = useState({});
  const [leaderboard, setLeaderboard] = useState({});
  const [isTryAgainTaken, setIsTryAgainTaken] = useState(
    cacheValues ? cacheValues.isTryAgainTaken : false
  );
  const SCREEN_WIDTH = Math.min(window.outerWidth, 430);
  const INVITE_LIMIT = 5;
  const CONSECUTIVE_DAYS_LIMIT = 7;
  const PREVIOUS_DAYS_LIMIT = 28;

  useEffect(() => {
    getPuzzles(previousDays);
    getLeaderboard();
    if (!!user) {
      getConsecutiveDays();
      calculateScore();
      getInviteCount();
      getPlayedCountsList(previousDays);
      getMyProfile();
      getMyLanguage();
    } else {
      // setPuzzleMap({});
      setPlayedCountsList(null);
      setMyProfile({});
      setMyLanguage({});
      localStorage.removeItem("subjects-objects-context");
    }
  }, [user, previousDays]);

  useEffect(() => {
    localStorage.setItem(
      "subjects-objects-context",
      JSON.stringify({
        previousDays,
        playedCountsList,
        isTryAgainTaken,
      })
    );
  }, [previousDays, playedCountsList, isTryAgainTaken]);

  const getPlayedCountsList = async (prevDays) => {
    let counts301 = 0;
    let counts303 = 0;
    let counts401 = 0;
    let counts404 = 0;

    const { data, error } = await supabase
      .from("scores")
      .select()
      .like(
        "puzzle_type",
        `%${moment().subtract(prevDays, "days").format("YYYYMMDD")}%`
      )
      .eq("user_id", user.id);

    if (error) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i].puzzle_type.includes("301.")) {
        counts301++;
      }
      if (data[i].puzzle_type.includes("303.")) {
        counts303++;
      }
      if (data[i].puzzle_type.includes("401.")) {
        counts401++;
      }
      if (data[i].puzzle_type.includes("404.")) {
        counts404++;
      }
    }

    setPlayedCountsList({
      301: counts301,
      303: counts303,
      401: counts401,
      404: counts404,
    });
  };

  const getPuzzles = async (prevDays) => {
    let day = moment().subtract(prevDays, "days").format("YYYYMMDD");
    // let day = moment().subtract(previousDay, "days").format("YYYYMMDD");
    const { data, error } = await supabase.storage.from("puzzles").list("", {
      // limit: 14,
      offset: 0,
      // search: "%401%", // works
      search: `%${day}%`,
      // sortBy: { column: "created_at", order: "desc" },
      sortBy: { column: "name", order: "asc" },
    });

    if (error) {
      console.log("An error occurred retrieving puzzles");
      return;
    }

    let map = {};
    if (data.length) {
      let puzzleNames = data.map((puzzle) => puzzle.name);
      for (let puzzle of puzzleNames) {
        let name = puzzle.substring(16, 19);
        map[name] = puzzle;
      }
    }

    setPuzzleMap({ ...map });
    return { ...map };
  };

  const getInviteCount = async () => {
    const { data, error } = await supabase
      .from("invitations")
      .select()
      .eq("referral_id", user.id);

    if (error) {
      return;
    }

    setInviteCount(data.length);
  };

  const checkInviteAvailable = async (email) => {
    const { data, error } = await supabase
      .from("invitations")
      .select()
      .eq("email", email);

    if (error) {
      return false;
    }

    if (data.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const getConsecutiveDays = async () => {
    const consecutive = await getConsecutive();

    if (consecutive) {
      const lastDateWithTime = new Date(consecutive.last);
      const lastDate = new Date(
        lastDateWithTime.getFullYear(),
        lastDateWithTime.getMonth(),
        lastDateWithTime.getDate()
      );
      const today = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );

      if (today - lastDate === 3600 * 24 * 1000 || today - lastDate === 0) {
        setConsecutiveDays(consecutive.consecutive);
      } else if (today - lastDate > 3600 * 24 * 1000) {
        setConsecutiveDays(0);
        const { error } = await supabase
          .from("consecutives")
          .update({
            consecutive: 0,
          })
          .eq("user_id", user.id);
      }
    }
  };

  const updateConsecutiveDays = async (consecutive, last, ever_consecutive) => {
    const { error } = await supabase
      .from("consecutives")
      .update({
        consecutive: consecutive,
        last: last,
        ever_consecutive: ever_consecutive,
      })
      .eq("user_id", user.id);

    if (error) return;

    setConsecutiveDays(consecutive);
  };

  const insertConsecutive = async (consecutive) => {
    const { error } = await supabase.from("consecutives").insert(consecutive);
  };

  const getConsecutive = async () => {
    const { data, error } = await supabase
      .from("consecutives")
      .select()
      .eq("user_id", user.id);

    if (error) {
      return null;
    }

    return data.length > 0 ? data[0] : null;
  };

  const insertScore = async (score) => {
    const { data, error } = await supabase.from("scores").insert(score);

    return { data, error };
  };

  const calculateScore = async () => {
    let imageSum = 0;
    let textSum = 0;
    let corrects = 0;
    let onlyImageCorrects = 0;
    let onlyTextCorrects = 0;
    let incorrects = 0;

    const { data, error } = await supabase
      .from("scores")
      .select()
      .eq("user_id", user.id);

    if (error) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      imageSum += data[i].image_score;
      textSum += data[i].text_score;

      if (data[i].image_score > 0 && data[i].text_score > 0) {
        corrects++;
      } else if (data[i].image_score > 0 && data[i].text_score == 0) {
        onlyImageCorrects++;
      } else if (data[i].image_score == 0 && data[i].text_score > 0) {
        onlyTextCorrects++;
      } else {
        incorrects++;
      }
    }

    setQuestionsCount(data.length);
    setCorrectCount(corrects);
    setTextOnlytCorrectCount(onlyTextCorrects);
    setImageOnlyCorrectCount(onlyImageCorrects);
    setIncorrectCount(incorrects);

    setImageScore(imageSum);
    setTextScore(textSum);
  };

  const getScoreFromPuzzle = async (puzzle) => {
    const { data, error } = await supabase
      .from("scores")
      .select()
      .eq("puzzle_type", puzzle);

    if (error) {
      return null;
    } else {
      return data;
    }
  };

  const getMyProfile = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .select()
      .eq("user_id", user.id);

    if (error) {
      return;
    }

    setMyProfile(data[0]);
  };

  const insertProfile = async (profile) => {
    const { data, error } = await supabase.from("profiles").insert(profile);

    return { data, error };
  };

  const updateProfile = async (profileData) => {
    const { error } = await supabase
      .from("profiles")
      .update(profileData)
      .eq("user_id", user.id);

    return { error };
  };

  const getMyLanguage = async () => {
    const { data, error } = await supabase
      .from("languages")
      .select()
      .eq("user_id", user.id);

    if (error) {
      return;
    }

    setMyLanguage(data[0]);
  };

  const insertLanguage = async (lang) => {
    const { data, error } = await supabase.from("languages").insert(lang);

    return { data, error };
  };

  const updateLanguage = async (langData) => {
    const { error } = await supabase
      .from("languages")
      .update(langData)
      .eq("user_id", user.id);

    return { error };
  };

  const insertFeedback = async (feedback) => {
    const { data, error } = await supabase.from("feedback").insert(feedback);

    return { data, error };
  };

  const getLeaderboard = async () => {
    const { data, error } = await supabase
      .from("leaderboard")
      .select()
      .order("id", { ascending: false })
      .limit(1)
      .single();

    if (error) return;

    setLeaderboard(data);
  };

  const updateFreeDailyPlays = async () => {
    const { data, error } = await supabase
      .from("global")
      .select()
      .order("id", { ascending: false })
      .limit(1);

    if (error) return;

    if (data.length === 0) {
      await supabase.from("global").insert({
        daily_free_plays: 1,
      });
    } else {
      const lastGlobal = data[0];
      if (
        moment(new Date()).format("YYYY-MM-DD") ===
        moment(lastGlobal.created_at).format("YYYY-MM-DD")
      ) {
        await supabase
          .from("global")
          .update({
            daily_free_plays: lastGlobal.daily_free_plays + 1,
          })
          .eq("id", lastGlobal.id);
      } else {
        await supabase.from("global").insert({
          daily_free_plays: 1,
        });
      }
    }
  };

  const updateDemoDailyPlays = async () => {
    const { data, error } = await supabase
      .from("global")
      .select()
      .order("id", { ascending: false })
      .limit(1);

    if (error) return;

    if (data.length === 0) {
      await supabase.from("global").insert({
        daily_demo_plays: 1,
      });
    } else {
      const lastGlobal = data[0];
      if (
        moment(new Date()).format("YYYY-MM-DD") ===
        moment(lastGlobal.created_at).format("YYYY-MM-DD")
      ) {
        await supabase
          .from("global")
          .update({
            daily_demo_plays: lastGlobal.daily_demo_plays + 1,
          })
          .eq("id", lastGlobal.id);
      } else {
        await supabase.from("global").insert({
          daily_demo_plays: 1,
        });
      }
    }
  };

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <AppContext.Provider
        value={{
          SCREEN_WIDTH,
          INVITE_LIMIT,
          CONSECUTIVE_DAYS_LIMIT, 
          PREVIOUS_DAYS_LIMIT, 
          user: user,
          consecutiveDays,
          imageScore,
          textScore,
          inviteCount,
          puzzleMap,
          questionsCount,
          correctCount,
          textOnlyCorrectCount,
          imageOnlyCorrectCount,
          incorrectCount,
          playedCountsList,
          previousDays,
          myProfile,
          myLanguage,
          leaderboard,
          isTryAgainTaken,
          setPreviousDays,
          getPlayedCountsList,
          getPuzzles,
          setConsecutiveDays,
          setInviteCount,
          getInviteCount,
          updateConsecutiveDays,
          getConsecutive,
          insertConsecutive,
          insertScore,
          calculateScore,
          getConsecutiveDays,
          getScoreFromPuzzle,
          getMyProfile,
          insertProfile,
          updateProfile,
          getMyLanguage,
          insertLanguage,
          updateLanguage,
          insertFeedback,
          checkInviteAvailable,
          setIsTryAgainTaken,
          updateFreeDailyPlays, 
          updateDemoDailyPlays, 
        }}
      >
        {children}
      </AppContext.Provider>
    </div>
  );
}
