import React, { useContext } from 'react';
import { Link, Outlet, useNavigate } from "react-router-dom";
import AboutUsModal from '../AboutUsModal/AboutUsModal';
import PrivacyPolicyModal from '../PrivacyPolicyModal/PrivacyPolicyModal';
import { useAuth } from "../../contexts/Auth";
import colors from "../../config/colors";
import { AppContext } from '../../contexts/AppContext';

const highlightedButtons = ["profile", "language", "/referral"];

function MenuButton({ icon, text = "Button", hoverText = "", link, target = "_self", onClick=(() => {}) }) {
  const [isHover, setIsHover] = React.useState(false);

  return (
    <Link
      style={{ textDecoration: "none" }}
      to={link || "/settings"}
      target={target}
    >
      <button
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={onClick}
        style={{
          ...styles.settingsButton,
          border:
            link && highlightedButtons.includes(link)
              ? "2px solid #75D875"
              : "none",
        }}
      >
        <span>
          {icon && (
            <img
              style={styles.settingsIcon}
              alt="Sounds"
              src={`/images/${icon}.svg`}
            />
          )}
          <span style={{ position: "relative", bottom: icon ? 5 : 0 }}>
            {hoverText && isHover ? hoverText : text}
          </span>
        </span>
        <img
          style={styles.settingsChevron}
          alt="Go to Sounds"
          src="/images/chevron_right.svg"
        />
      </button>
    </Link>
  );
}

function Settings() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [showAboutModal, setShowAboutModal] = React.useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = React.useState(false);
  // Get current user and signOut function from context
  const { signOut } = useAuth();

  async function handleSignOut() {
    // console.log("signing out");
    localStorage.setItem("splashShown", "false");

    // Ends user session
    await signOut();

    // Redirects the user to Login page
    navigate("/signin");
  }

  return (
    <div style={styles.menu}>
      <Outlet />
      <div
        style={{
          width: appContext.SCREEN_WIDTH,
          overflow: "auto",
          textAlign: "center",
          paddingTop: 40,
          paddingBottom: 20,
        }}
      >
        <div style={{ width: "90%", display: "inline-block" }}>
          <div
            style={{
              fontSize: 28,
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <img
              src="./images/chevron_left.svg"
              style={{
                cursor: "pointer",
                width: 25,
                height: 25,
                marginTop: 7,
              }}
              alt=""
              onClick={() => navigate("/", { state: { from: "settings" } })}
            />
            <p style={styles.header}>Settings</p>
            <img
              src="./images/chevron_right.svg"
              style={{
                cursor: "pointer",
                width: 25,
                height: 25,
                marginTop: 7,
                visibility: "hidden",
              }}
              alt=""
            />
          </div>
          <MenuButton icon="profile_icon" text="Profile" link="/profile" />
          <MenuButton icon="language_icon" text="Language" link="/language" />
          <MenuButton icon="sounds_icon" text="Sounds" />
          {/* <MenuButton
            icon="statistics_icon"
            text="Statistics"
            link="statistics"
          /> */}
          <MenuButton 
            text="About Us" 
            onClick={() => setShowAboutModal(true)}
          />
          <MenuButton
            text="Contact Us"
            hoverText="subjects.objects.game@gmail.com"
            link="https://sno-app.webflow.io/"
            target="_blank"
          />
          <MenuButton
            text="Terms of Service"
            link="https://sno-app.webflow.io/"
          />
          <MenuButton
            text="Privacy Policy"
            onClick={() => setShowPrivacyModal(true)}
          />
        </div>
        {showAboutModal && <AboutUsModal setShowAboutModal={setShowAboutModal} />}
        {showPrivacyModal && <PrivacyPolicyModal setShowPrivacyModal={setShowPrivacyModal} />}
      </div>
    </div>
  );
}

export default Settings;

const BUTTONHEIGHT = 65;

const styles = {
  menu: {
    // position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100vw",
    // height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "#F0F6FC",
    zIndex: 3,
  },
  settings: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    // width: "100%",
    // height: "100%",
    width: "100vw",
    // height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F0F6FC",
    // zIndex: 1,
  },
  header: {
    color: colors.header,
    fontSize: 36,
    fontWeight: "bold",
    marginBottom: 20,
  },
  settingsButton: {
    backgroundColor: colors.settingsButton,
    width: "100%",
    height: BUTTONHEIGHT,
    borderRadius: BUTTONHEIGHT / 2,
    border: "none",
    textAlign: "left",
    fontSize: 18,
    fontWeight: 600,
    color: colors.text,
    padding: "0px 22px 0px 22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 10px 0px",
    cursor: "pointer",
  },
  settingsIcon: {
    width: 22,
    height: 22,
    marginRight: 12,
    marginTop: 5,
  },
  settingsChevron: {
    width: 13,
    height: 13,
  },
};
