import { useState, useContext } from "react";
import { Button } from "../GameScreen/GameScreen";
import { supabase } from "../../supabase"
import './ResetPassword.scss'
import { AppContext } from "../../contexts/AppContext";

export default function ResetPassword() {
  const appContext = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email);

    if (data) {
      setSuccessMessage("Success! Please check your email to reset your password.");
      setErrorMessage("");
    }

    if (error) {
      setErrorMessage("Please input your valid email.");
      setSuccessMessage("");
    }
  }

  return (
    <div className="reset-password-container"
      style={{
        maxWidth: appContext.SCREEN_WIDTH
      }}
    >
      <div className="reset-password-wrapper">
        <p className="text-bold mb-30">Enter your email</p>
        <input
          className="email-input"
          type="email"
          required
          rows={1}
          placeholder="example@gmail.com"
          autoComplete="on"
          onChange={(e) => setEmail(e.target.value)}
          name="emailContent"
          value={email}
        />
        {successMessage != "" && <p className="text-success mt-10">{successMessage}</p>}
        {errorMessage != "" && <p className="text-danger mt-10">{errorMessage}</p>}
        <div>
          <br />
          <Button
            buttonText="Submit"
            width={200}
            disabled={email === ""}
            handleConfirmSelection={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
