import { useContext, useState } from "react";
import { Button } from "../GameScreen/GameScreen";
import { supabaseAdmin } from "../../supabase";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import emailjs from "@emailjs/browser";

const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export default function InvitationScreen({ user }) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [emailTo, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInvite = async () => {
    if (!user) return;

    const inviteData = {
      from_email: user.email,
      to_email: emailTo,
      referral_email: user.email,
      referral_username: appContext.myProfile.username,
      invite_link: `${process.env.REACT_APP_SERVER_URL}/confirm-invite/${emailTo}/${user.id}`,
    };

    const inviteAvailable = await appContext.checkInviteAvailable(emailTo);

    if (!emailTo.match(isValidEmail)) {
      setErrorMessage("Please enter a valid email address.");
      setSuccessMessage("");
    } else if (!inviteAvailable) {
      setErrorMessage("An account is already associated with this email");
      setSuccessMessage("");
      return;
    } else {
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_INVITE_TEMPLATE_ID,
          inviteData,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then((response) => {
          setSuccessMessage(`Successfully sent an invite to ${emailTo}.`);
          setErrorMessage("");
          setEmail("");
        })
        .catch((error) => {
          setErrorMessage(error.text);
          setSuccessMessage("");
        });
    }
  };

  return (
    <div
      className="flex-column"
      style={{
        ...styles.navbar,
        width: Math.round(appContext.SCREEN_WIDTH * 0.907)
        // justifyContent: "center",
        // alignItems: "center",
      }}
    >
      <div
        className="d-flex justify-content-between mb-20 pt-100"
        style={{
          fontSize: 28,
        }}
      >
        <img
          src="./images/chevron_left.svg"
          style={{
            cursor: "pointer",
            width: 25,
            height: 25,
            marginTop: 7,
          }}
          alt=""
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div
        className="mt-100"
        style={{
          textAlign: "center",
          display: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="mb-10 text-start font-bold">Refer your friends to play Subjects & Objects.</p>
        <p className="mb-20 text-start font-bold">You currently have {appContext.inviteCount} successful referrals.</p>
        <input
          type="email"
          style={{
            textAlign: "center",
            border: "0",
            borderRadius: 20,
            padding: 10,
            fontSize: 17,
            width: "100%",
          }}
          required
          rows={1}
          placeholder="example@gmail.com"
          autoComplete="on"
          onChange={(e) => setEmail(e.target.value)}
          name="emailContent"
          value={emailTo}
        />
        {successMessage != "" && <p className="text-success mt-10">{successMessage}</p>}
        {errorMessage != "" && <p className="text-danger mt-10">{errorMessage}</p>}
        <div>
          <br />
          <Button
            buttonText="Invite"
            width={200}
            // width="100%"
            disabled={emailTo === ""}
            handleConfirmSelection={handleInvite}
          />
        </div>
        <p className="mt-10 text-start">The invite can expire after 4-5 days. You may need to re-invite your friend.</p>
      </div>
    </div>
  );
}

const styles = {
  navbar: {
    position: "relative",
    height: "100vh",
    margin: "auto"
  },
};
