import { useNavigate } from "react-router-dom";

export default function BackNavigation() {
  const navigate = useNavigate();
  
  return (
    <div
      className="d-flex justify-content-between mb-20"
    >
      <img
        src="./images/chevron_left.svg"
        style={{
          cursor: "pointer",
          width: 25,
          height: 25,
          marginTop: 7,
        }}
        alt=""
        onClick={() => {
          navigate("/");
        }}
      />
    </div>
  )
}