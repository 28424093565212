import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import { useNavigate } from "react-router-dom";

import "./Leaderboard.scss";

function Leaderboard() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const leaderboardValues = appContext.leaderboard;

  return (
    <div
      className="leaderboard-container"
      style={{
        display: "flex",
      }}
      onClick={() => {
        navigate("/");
      }}
    >
      <div
        className="leaderboard-wrapper"
        style={{
          width: appContext.SCREEN_WIDTH * 0.907,
        }}
      >
        <>
          <p className="text-center mb-30 font-bold">Leaderboard</p>
          <table>
            <tbody>
              <tr>
                <td>Most Points by Week</td>
                <td>{leaderboardValues.max_score_week}</td>
                <td>
                  {leaderboardValues.max_score_user_count_week > 1
                    ? `${leaderboardValues.max_score_user_count_week} players`
                    : leaderboardValues.max_score_username_week}
                </td>
              </tr>
              <tr>
                <td>Most Points by Month</td>
                <td>{leaderboardValues.max_score_month}</td>
                <td>
                  {leaderboardValues.max_score_user_count_month > 1
                    ? `${leaderboardValues.max_score_user_count_month} players`
                    : leaderboardValues.max_score_username_month}
                </td>
              </tr>
              <tr>
                <td>Most Correct Questions by Week</td>
                <td>{leaderboardValues.max_correct_week}</td>
                <td>
                  {leaderboardValues.max_correct_user_count_week > 1
                    ? `${leaderboardValues.max_correct_user_count_week} players`
                    : leaderboardValues.max_correct_username_week}
                </td>
              </tr>
              <tr>
                <td>Most Correct Questions by Month</td>
                <td>{leaderboardValues.max_correct_month}</td>
                <td>
                  {leaderboardValues.max_correct_user_count_month > 1
                    ? `${leaderboardValues.max_correct_user_count_month} players`
                    : leaderboardValues.max_correct_username_month}
                </td>
              </tr>
              <tr>
                <td>Most Consecutive Days</td>
                <td>{leaderboardValues.max_consecutive}</td>
                <td>
                  {leaderboardValues.max_consecutive_user_count > 1
                    ? `${leaderboardValues.max_consecutive_user_count} players`
                    : leaderboardValues.max_consecutive_username}
                </td>
              </tr>
              <tr>
                <td>Most Consecutive Days Ever</td>
                <td>{leaderboardValues.max_ever_consecutive}</td>
                <td>
                  {leaderboardValues.max_ever_consecutive_user_count > 1
                    ? `${leaderboardValues.max_ever_consecutive_user_count} players`
                    : leaderboardValues.max_ever_consecutive_username}
                </td>
              </tr>
              <tr>
                <td>Most Referrals Total</td>
                <td>{leaderboardValues.max_invite_total}</td>
                <td>
                  {leaderboardValues.max_invite_user_count_total > 1
                    ? `${leaderboardValues.max_invite_user_count_total} players`
                    : leaderboardValues.max_invite_username_total}
                </td>
              </tr>
              <tr>
                <td>Most Referrals by Month</td>
                <td>{leaderboardValues.max_invite_month}</td>
                <td>
                  {leaderboardValues.max_invite_user_count_month > 1
                    ? `${leaderboardValues.max_invite_user_count_month} players`
                    : leaderboardValues.max_invite_username_month}
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text-primary text-center" style={{
            color: "rgba(93, 100, 118, 0.8)",
            fontSize: 20,
            marginTop: 10,
          }}>Tap to continue</p>
        </>
      </div>
    </div>
  )
}

export default Leaderboard;
