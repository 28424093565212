import { useState, useEffect, useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as xlsx from "xlsx";
import colors from "../../config/colors";
import PromptScreen from "../PromptScreen";
import ResultsModal from "../ResultsModal/ResultsModal";
import Grid from "../Grid/Grid";
// import Timer from "../Timer";
// import HintButton from "../HintButton";
// import { LongPressEventType, useLongPress } from "use-long-press";
import ImageModal from "../ImageModal/ImageModal";
import TryAgainModal from "../TryAgainModal/TryAgainModal";
import moment from "moment";
import { AppContext } from "../../contexts/AppContext";

const CDN_PUZZLES_URL =
  process.env.REACT_APP_SUPABASE_URL + "/storage/v1/object/public/puzzles/";
const CDN_IMAGES_URL =
  process.env.REACT_APP_SUPABASE_URL + "/storage/v1/object/public/images/";

function GameScreen({ user }) {
  const appContext = useContext(AppContext);
  const [userData, setUserData] = useState({});
  const [rows, setRows] = useState(0);
  const [columns, setColumns] = useState(0);
  const [images, setImages] = useState([]);
  const [questions, setQuestions] = useState([{ Question: "" }]);
  const [showPrompt, setShowPrompt] = useState(true);
  const [coordinates, setCoordinates] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  //
  // const [score, incrementScore] = useState(0);
  const [answerScore, setAnswerScore] = useState(0);
  const [imageScore, setImageScore] = useState(0);
  const [gameImageScore, setGameImageScore] = useState(0);
  const [gameTextScore, setGameTextScore] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [imageOnlyCorrectCount, setImageOnlyCorrectCount] = useState(0);
  const [textOnlyCorrectCount, setTextOnlyCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [hintPenalty, setHintPenalty] = useState(0);
  // const [hintsUsed, setHintsUsed] = useState(0);
  const hintsUsed = 0;
  //
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(undefined);
  const [questionNumber, setQuestionNumber] = useState(1);
  // const [hint1, setHint1] = useState(null);
  // const [hint2, setHint2] = useState(null);
  // const [enabled, setEnabled] = useState(true);
  const enabled = true;
  const [longPressed, setLongPressed] = useState(false);
  const [gridSize, setGridSize] = useState("");
  const [showSelectedModal, setShowSelectedModal] = useState(false);
  const [answer, setAnswer] = useState("");
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [showTryAgainModal, setShowTryAgainModal] = useState(false);
  const [isImageCorrect, setIsImageCorrect] = useState(true);
  const [isTextCorrect, setIsTextCorrect] = useState(true);
  const [feedback, setFeedback] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [magnifiedImage, setMagnifiedImage] = useState(null);
  const currentDay = moment().format("MMMM DD, YYYY");
  const { state } = useLocation();
  const navigate = useNavigate();
  const [puzzle, setPuzzle] = useState(null);
  const [puzzleType, setPuzzleType] = useState("");

  useEffect(() => {
    if (state && state.puzzle) {
      setPuzzle(state.puzzle);
    }
  }, [state]);

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      let PUZZLE_URL = CDN_PUZZLES_URL + puzzle;
      const data = await (await fetch(PUZZLE_URL)).arrayBuffer();
      const workbook = xlsx.read(data); // xlsx.read(data, { type: "binary" })
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);

      let questions = json.map((item, i) => {
        let Question = item["Question"];
        let Question_Seq = item["Question_Seq"];
        let L_Listing = item["L_Listing"];
        let R_Listing = item["R_Listing"];
        let Reason = item["Reason"];
        let L_TXT00 = item["L_TXT00"];
        let R_Drive_Dir_File = item["R_Drive_Dir_File"];
        let Random_ID = item["Random_ID"];
        let Exact = item["Exact"];
        let L_Plural_TXT00 = item["L_Plural_TXT00"];
        let L_Alt_TXT00 = item["L_Alt_TXT00"];
        let answer = i;

        // if (item.Hint_1 !== undefined) {
        //   setHint1(item.Hint_1);
        // }

        // if (item.Hint_2 !== undefined) {
        //   setHint2(item.Hint_2);
        // }

        return {
          Question,
          Question_Seq,
          L_Listing,
          R_Listing,
          Reason,
          L_TXT00,
          R_Drive_Dir_File,
          answer,
          Random_ID,
          Exact,
          L_Plural_TXT00,
          L_Alt_TXT00
        };
      });

      questions = questions.filter(
        (item) => item["Question"] !== "" && item["Question_Seq"] !== undefined
      );

      questions = questions.sort((a, b) => a.Question_Seq - b.Question_Seq);

      let currentPuzzletype = "";
      if (puzzle.includes("301.")) {
        currentPuzzletype = "301";
      }

      if (puzzle.includes("303.")) {
        currentPuzzletype = "303";
      }

      if (puzzle.includes("401.")) {
        currentPuzzletype = "401";
      }

      if (puzzle.includes("404.")) {
        currentPuzzletype = "404";
      }

      setPuzzleType(currentPuzzletype);

      const playedCount = appContext.playedCountsList[currentPuzzletype];

      if (playedCount > 0 && playedCount < parseInt(currentPuzzletype.slice(-1))) {
        questions = questions.slice(playedCount);
      }

      let Grid_Size = json[0]["Grid_Size"];
      let [rows, columns] = Grid_Size.split("x");
      let coordinates = json.map((item) => item["X"] + "" + item["Y"]);

      setGridSize(Grid_Size);
      setRows(rows);
      setColumns(columns);
      setImages(json);
      setQuestions(questions);
      setCoordinates(coordinates);
    };

    if (puzzle) {
      fetchData();
    }
  }, [CDN_PUZZLES_URL, puzzle]);

  useEffect(() => {
    if (questions.length === 0
      && appContext.playedCountsList[puzzleType] < parseInt(puzzleType.slice(-1))
      // && appContext.previousDays === 0
    ) {
      setShowStatsModal(true);
    }
  }, [questions]);

  function handleCycleQuestions() {
    if ((appContext.playedCountsList[puzzleType] > parseInt(puzzleType.slice(-1)) - 1)
      && questions.length === 1) {
      navigate("/");
    } else {
      if (!localStorageGameStateExists()) {
        initializeLocalStorage();
      }
      updateLocalStorage();
      let updatedQuestions = [...questions.slice(1)]; // removes the first question from array
      setQuestions(updatedQuestions);
      handleShowModal(false);
      setQuestions(updatedQuestions);
      setSelectedImage(undefined);
      setShowPrompt(true);
      if (updatedQuestions.length > 0) {
        setQuestionNumber(questionNumber + 1);
      }
      // Reset scores for next question
      setImageScore(0);
      setAnswerScore(0);
      setHintPenalty(0);
    }
  }

  function localStorageGameStateExists() {
    let gameState = localStorage.getItem("gameState");
    return gameState !== null;
  }

  function initializeLocalStorage() {
    // console.log("initializing gameState");
    let puzzleName = puzzle.replace(".xlsx", "");
    let gameStateData = [
      {
        puzzleName: puzzleName,
        questionsAnswered: [],
        dateStarted: moment().format(),
        dateCompleted: null,
      },
    ];
    localStorage.setItem("gameState", JSON.stringify(gameStateData));
  }

  function updateLocalStorage() {
    let puzzleName = puzzle.replace(".xlsx", "");
    let gameState = localStorage.getItem("gameState");
    let gameStateData = [...JSON.parse(gameState)];

    // check if data for this puzzle is in localStorage
    let puzzleData = gameStateData.find(
      (data) => data.puzzleName === puzzleName
    );

    if (!puzzleData) {
      gameStateData.push({
        puzzleName: puzzleName,
        questionsAnswered: [],
        dateStarted: moment().format(),
        dateCompleted: null,
      });

      localStorage.setItem("gameState", JSON.stringify(gameStateData));
    }

    // re-initalize gameStateData
    gameState = localStorage.getItem("gameState");
    gameStateData = [...JSON.parse(gameState)];

    let updatedGameStateData = gameStateData.map((data) => {
      if (data.puzzleName === puzzleName) {
        return {
          ...data,
          questionsAnswered: [
            ...data.questionsAnswered,
            {
              questionNumber: questionNumber,
              answerProvided: answerScore,
              imageSelected: imageScore,
              hintsUsed: 0,
              // answerProvided: 1000,
              // imageSelected: 1000,
              // hintsUsed: -500,
            },
          ],
        };
      }
      return data;
    });

    localStorage.setItem("gameState", JSON.stringify(updatedGameStateData));
  }

  function handleSelectedImage(imageData) {
    if (!questions.length) return; // prevent user from selecting image if there are no questions
    setSelectedImage(
      JSON.stringify(imageData) === JSON.stringify(selectedImage)
        ? undefined
        : imageData
    );
  }

  function handleConfirmSelection() {
    let imageIndex = coordinates.indexOf(
      selectedImage["X"] + "" + selectedImage["Y"]
    );

    let correctIndex = questions[0]["answer"];

    if (imageIndex === correctIndex) {
      setCorrectAnswer(true);
      handleShowModal(true);
      // handleIncrementScore();
    } else {
      setCorrectAnswer(false);
      handleShowModal(true);
    }

    appContext.setIsTryAgainTaken(false);
  }

  function handleShowModal(bool) {
    setShowModal(bool);
  }

  // const callback = useCallback((event) => {
  //   // event is optional
  //   // alert('Long pressed!');
  //   setLongPressed(true);
  // }, []);

  // const bind = useLongPress(enabled ? callback : null, {
  //   // onStart: (event, meta) => {
  //   //   // console.log("Press started", meta);
  //   // },
  //   // onFinish: (event, meta) => {
  //   //   // console.log("Long press finished", meta);
  //   // },
  //   // onCancel: (event, meta) => {
  //   //   // console.log("Press cancelled", meta);
  //   // },
  //   // onMove: () => console.log("Detected mouse or touch movement"),
  //   filterEvents: (event) => true, // All events can potentially trigger long press
  //   threshold: 1000, // milliseconds
  //   captureEvent: true, // if true, prevent event from clearing after React processes it
  //   cancelOnMovement: false, // square size (px) inside which movement won't canel long press
  //   cancelOutsideElement: true, // cancels long press when mouse goes outside element
  //   detect: LongPressEventType.Pointer,
  // });

  function redirectUser() {
    if (gameOver) {
      return navigate("/");
    }
  }

  function handleAnswer() {
    let currentQuestion = questions[0];
    const exact = currentQuestion.Exact;
    const isImgCorrect = (currentQuestion.R_Drive_Dir_File === selectedImage.R_Drive_Dir_File);
    const isTxtCorrect = (exact ? currentQuestion.L_TXT00 === answer :
      (currentQuestion.L_TXT00.toLowerCase() === answer.toLowerCase().trim() ||
        (!!currentQuestion.L_Alt_TXT00 ? currentQuestion.L_Alt_TXT00.toLowerCase() === answer.toLowerCase().trim() : false) ||
        (!!currentQuestion.L_Plural_TXT00 ? currentQuestion.L_Plural_TXT00.toLowerCase() === answer.toLowerCase().trim() : false))
    );
    const isTryAgainTaken = appContext.isTryAgainTaken;

    if ((isImgCorrect && isTxtCorrect) || isTryAgainTaken) {
      updateScore();
      setAnswer("");
      // let unshiftedQuestions = [...questions.slice(1)];
      // setQuestions(unshiftedQuestions);
      handleConfirmSelection();
      // setSelectedImage(undefined);
      setShowSelectedModal(false);
    } else {
      setIsImageCorrect(isImgCorrect);
      setIsTextCorrect(isTxtCorrect);
      setShowTryAgainModal(true);
    }
  }

  async function updateScore() {
    let currentQuestion = questions[0];
    const exact = currentQuestion.Exact;
    const isImgCorrect = (currentQuestion.R_Drive_Dir_File === selectedImage.R_Drive_Dir_File);
    const isTxtCorrect = exact ? currentQuestion.L_TXT00 === answer :
      (currentQuestion.L_TXT00.toLowerCase() === answer.toLowerCase().trim() ||
        (!!currentQuestion.L_Alt_TXT00 ? currentQuestion.L_Alt_TXT00.toLowerCase() === answer.toLowerCase().trim() : false) ||
        (!!currentQuestion.L_Plural_TXT00 ? currentQuestion.L_Plural_TXT00.toLowerCase() === answer.toLowerCase().trim() : false));

    let score = {
      user_id: user.id,
      image_score: 0,
      text_score: 0,
      puzzle_type: puzzle,
      question_seq: currentQuestion["Question_Seq"],
      text_entered: answer,
      image_selected: selectedImage,
      random_id: currentQuestion["Random_ID"]
    }

    if (isImgCorrect) {
      const currentImageScore = appContext.isTryAgainTaken ? 750 : 1000;
      setImageScore(currentImageScore);
      setGameImageScore(gameImageScore + currentImageScore);
      // if (appContext.previousDays === 0) {
      score.image_score = currentImageScore;
      // }
    }

    if (isTxtCorrect) {
      const currentTextScore = appContext.isTryAgainTaken ? 750 : 1000;
      setAnswerScore(currentTextScore);
      setGameTextScore(gameTextScore + currentTextScore);
      // if (appContext.previousDays === 0) {
      score.text_score = currentTextScore;
      // }
    }

    if (isImgCorrect && isTxtCorrect) {
      setCorrectCount(correctCount + 1);
    } else if (isImgCorrect && !isTxtCorrect) {
      setImageOnlyCorrectCount(imageOnlyCorrectCount + 1);
    } else if (!isImgCorrect && isTxtCorrect) {
      setTextOnlyCorrectCount(textOnlyCorrectCount + 1);
    } else {
      setIncorrectCount(incorrectCount + 1);
    }

    if (appContext.playedCountsList[puzzleType] < parseInt(puzzleType.slice(-1))) {
      const { error } = await appContext.insertScore(score);
      // Handle hints used here

      if (error) {
        return;
      }

      if (appContext.previousDays === 0) {
        updateConsecutive();
      }

      appContext.calculateScore();
    }
  }

  const updateConsecutive = async () => {
    const consecutive = await appContext.getConsecutive();

    if (consecutive) {
      const lastDateWithTime = new Date(consecutive.last);
      const lastDate = new Date(lastDateWithTime.getFullYear(), lastDateWithTime.getMonth(), lastDateWithTime.getDate());
      const today = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate());

      if (today - lastDate >= 3600 * 24 * 1000) {
        if (consecutive.ever_consecutive < appContext.consecutiveDays + 1) {
          await appContext.updateConsecutiveDays(appContext.consecutiveDays + 1, new Date(), appContext.consecutiveDays + 1);
        } else {
          await appContext.updateConsecutiveDays(appContext.consecutiveDays + 1, new Date(), consecutive.ever_consecutive);
        }
      } else {
        // await appContext.updateConsecutiveDays(appContext.consecutiveDays, new Date());
      }
    } else {
      let consecutive = {
        user_id: user.id,
        consecutive: 1,
        ever_consecutive: 1
      }

      await appContext.insertConsecutive(consecutive);
    }
  }

  // function updateScore() {
  //   let totalPoints = 0;
  //   let currentQuestion = questions[0];
  //   if (currentQuestion.R_Drive_Dir_File === selectedImage.R_Drive_Dir_File) {
  //     // selected image was correct
  //     totalPoints += 1000;
  //   }
  //   if (currentQuestion.L_TXT00 === answer) {
  //     // written answer was correct
  //     totalPoints += 1000;
  //   }
  //   incrementScore(score + totalPoints);
  //   // TODO: - Update user's statistics metadata
  // }

  const submitFeedback = async (feedback) => {
    const feedbackForSend = {
      user_id: user.id,
      message: feedback
    }

    const { error } = await appContext.insertFeedback(feedbackForSend);

    if (error) return;

    setFeedbackSubmitted(true);
  }

  // async function updateUserData() {
  //   let puzzleName = puzzle.replace(".xlsx", "");
  //   let gameState = localStorage.getItem("gameState");
  //   let gameStateData = [...JSON.parse(gameState)];

  //   let updatedGameStateData = gameStateData.map((data) => {
  //     if (data.puzzleName === puzzleName) {
  //       return {
  //         ...data,
  //         dateCompleted: moment().format(),
  //       };
  //     }
  //     return data;
  //   });

  //   localStorage.setItem("gameState", JSON.stringify(updatedGameStateData));

  //   // only update is user data is not empty and if user has completed puzzle for the first time
  //   if (!userData.user_metadata) return;
  //   let puzzleData = userData.user_metadata.puzzlesPlayed.find(
  //     (data) => data.puzzleName === puzzleName
  //   );
  //   if (puzzleData) {
  //     // alert("User has already completed puzzle, do not update user_metadata");
  //     return;
  //   }

  //   /*
  //     let puzzlesPlayed = [1,2]
  //     let updatedPuzzlesPlayed = [...puzzlesPLayed, ...[3,4]]
  //   */
  //   let updatedPuzzlesPlayed = [
  //     ...userData.user_metadata.puzzlesPlayed,
  //     ...updatedGameStateData,
  //   ];

  //   const { data, error } = await supabase.auth.updateUser({
  //     data: {
  //       puzzlesPlayed: updatedPuzzlesPlayed,
  //       statistics: {
  //         ...user.user_metadata.statistics,
  //         totalPoints: calculateScore(),
  //       },
  //     },
  //   });

  //   if (error) {
  //     console.log("Error updating puzzles played");
  //     return;
  //   }

  //   // getUserSession()
  //   // alert("Successfully updated puzzles played");
  // }

  return (
    <div style={styles.gameScreen}>
      {/* {redirectUnauthorizedUser()} */}
      {redirectUser()}
      {/* <p>{score}</p> */}
      {/* <p>{answer}</p> */}
      {questions.length > 0 && (
        <PromptScreen
          phrase={questions[0]["Question"]}
          showPrompt={showPrompt}
          setShowPrompt={setShowPrompt}
          questionNumber={questionNumber}
        />
      )}
      {showTryAgainModal && <TryAgainModal
        isImageCorrect={isImageCorrect}
        isTextCorrect={isTextCorrect}
        handleTryAgain={() => {
          appContext.setIsTryAgainTaken(true);
          setShowTryAgainModal(false);
        }}
      />}
      {!!magnifiedImage && <ImageModal
        longPressed={longPressed}
        magnifiedImage={magnifiedImage}
        setLongPressed={setLongPressed}
      />}
      <ResultsModal
        showModal={showModal}
        selectedImage={selectedImage}
        correctImage={
          questions.length ? questions[0]["R_Drive_Dir_File"] : null
        }
        correctAnswer={correctAnswer}
        handleShowModal={handleShowModal}
        handleCycleQuestions={handleCycleQuestions}
        question={questions[0]}
        // score={score}
        score={imageScore + answerScore}
        alreadyPlayed={appContext.playedCountsList[puzzleType] > parseInt(puzzleType.slice(-1)) - 1}
      />
      <div
        className="d-flex justify-content-between mt-10 mb-10"
        style={{
          width: '100%',
          maxWidth: appContext.SCREEN_WIDTH,
          paddingLeft: 26,
          paddingRight: 26
        }}
      >
        <img
          src="./images/chevron_left.svg"
          style={{
            cursor: "pointer",
            width: 25,
            height: 25,
            marginTop: 7,
          }}
          alt=""
          onClick={() => {
            appContext.getPlayedCountsList(appContext.previousDays);
            navigate("/");
          }}
        />
      </div>
      <p style={{ fontSize: 23, marginBottom: 10 }}>Phrase</p>
      {questions.length > 0 && (
        <p style={{
          ...styles.phrase,
          maxWidth: appContext.SCREEN_WIDTH
        }}>{questions[0]["Question"]}</p>
      )}
      <input
        style={styles.input}
        type="text"
        placeholder="Type your answer..."
        onChange={(e) => setAnswer(e.target.value)}
        value={answer}
      />
      <StatsModal
        showStatsModal={showStatsModal}
        setShowStatsModal={setShowStatsModal}
        setShowFeedbackModal={setShowFeedbackModal}
        grid={gridSize}
        currentDay={currentDay}
        score={gameImageScore + gameTextScore}
        correctCount={correctCount}
        imageOnlyCorrectCount={imageOnlyCorrectCount}
        textOnlyCorrectCount={textOnlyCorrectCount}
        incorrectCount={incorrectCount}
        hintsUsed={hintsUsed}
        userData={userData}
        setGameOver={setGameOver}
        updateUserData={() => { }}
      />
      <FeedbackModal
        showFeedbackModal={showFeedbackModal}
        feedback={feedback}
        setFeedback={setFeedback}
        feedbackSubmitted={feedbackSubmitted}
        setShowFeedbackModal={setShowFeedbackModal}
        submitFeedback={submitFeedback}
        grid={gridSize}
        currentDay={currentDay}
        setShowStatsModal={setShowStatsModal}
      />
      {/* <div className="mt-10 mb-10 font-bold text-center">Click/hold to enlarge image for better look</div> */}
      <div className="mb-30" style={{
        ...styles.gridWrapper,
        maxWidth: appContext.SCREEN_WIDTH
      }}>
        <Grid
          rows={rows}
          columns={columns}
          images={images}
          selectedImage={selectedImage}
          handleSelectedImage={handleSelectedImage}
          coordinates={coordinates}
          setLongPressed={setLongPressed}
          setMagnifiedImage={setMagnifiedImage}
        />
      </div>
      {/* <Timer /> */}
      {/* {hint1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 0px 20px 0px",
          }}
        >
          <HintButton hintNumber={1} hint={hint1} />
          {hint2 && <HintButton hintNumber={2} hint={hint2} />}
        </div>
      )} */}
      <div
        style={{
          margin: "10px 0px",
        }}
      >
        <button
          onClick={handleAnswer}
          style={{
            ...(selectedImage === undefined || answer == ""
              ? styles.disabledButton
              : styles.button),
            width: Math.round(appContext.SCREEN_WIDTH * 0.907),
          }}
          disabled={!questions.length || selectedImage === undefined || answer == ""}
        >
          Confirm Selection
        </button>
      </div>
    </div>
  );
}

export default GameScreen;

export function Button({
  buttonText = "Button",
  width = "100%",
  disabled,
  handleConfirmSelection = () => console.log("Clicked button"),
}) {
  return (
    <button
      style={{ ...(disabled ? styles.disabledButton : styles.button), width }}
      onClick={handleConfirmSelection}
      disabled={disabled}
    >
      {buttonText}
    </button>
  );
}

function StatsModal({
  showStatsModal,
  setShowStatsModal,
  setShowFeedbackModal,
  grid,
  currentDay,
  score,
  correctCount,
  imageOnlyCorrectCount,
  textOnlyCorrectCount,
  incorrectCount,
  hintsUsed,
  userData, // needed for referrals & consecutive days
  setGameOver,
  updateUserData,
}) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  function getMultiplier(referrals) {
    return 1 + referrals / 10;
  }

  return (
    <div
      style={{
        ...styles.modalScreen,
        visibility: showStatsModal ? "visible" : "hidden",
      }}
    >
      <div style={{
        ...styles.modal,
        textAlign: "center",
        width: Math.round(appContext.SCREEN_WIDTH * 0.907)
      }}>
        <h1 style={styles.header}>Game Stats</h1>
        <div
          style={{
            color: colors.header,
            textAlign: "center",
            marginBottom: 20,
          }}
        >
          {grid} Grid - <strong>{currentDay}</strong>
        </div>
        <div className="mb-10" style={{ color: "#5D6476", fontSize: 28 }}>
          <span style={{ color: score > 0 ? "#75D875" : "" }}>
            <strong>{`${score} `}</strong>
          </span>
          pts
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <p className="text-start">Total Correct Questions</p>
          <p style={{ color: "#171D2E", fontWeight: "bold" }}>{correctCount}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <p className="text-start">Total Partially Correct Questions (text) </p>
          <p style={{ color: "#171D2E", fontWeight: "bold" }}>{textOnlyCorrectCount}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <p className="text-start">Total Partially Correct Questions (image) </p>
          <p style={{ color: "#171D2E", fontWeight: "bold" }}>{imageOnlyCorrectCount}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <p className="text-start">Total Incorrect Questions</p>
          <p style={{ color: "#171D2E", fontWeight: "bold" }}>{incorrectCount}</p>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <p>Time Taken</p>
          <p style={{ color: "#171D2E", fontWeight: "bold" }}>01:35.50</p>
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <p className="text-start">Hints Used</p>
          <p style={{ color: "#171D2E", fontWeight: "bold" }}>{hintsUsed}</p>
        </div>
        {/* <hr style={{ ...styles.divider, marginBottom: 20 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <p>Referrals</p>
          <p style={styles.stat}>
            {appContext.inviteCount}{" "}
            <span style={{ color: "#75D875" }}>
              (X{getMultiplier(appContext.inviteCount)})
            </span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <p>Consecutive Days</p>
          <p style={styles.stat}>
            {appContext.consecutiveDays}{" "}
            <span style={{ color: "#75D875" }}>
              (+{appContext.consecutiveDays * 100} pts)
            </span>
          </p>
        </div> */}
        <div style={{ display: "flex", marginTop: 10 }}>
          <div style={{ width: "50%", paddingRight: 5 }}>
            <button
              style={{
                ...styles.invertedButton,
                width: "100%",
              }}
              onClick={() => {
                setShowStatsModal(false);
                setShowFeedbackModal(true);
              }}
            >
              Feedback
            </button>
          </div>
          <div style={{ width: "50%", paddingLeft: 5 }}>
            <button
              style={{
                ...styles.button,
                backgroundColor: "#5D6476",
                width: "100%",
              }}
              onClick={() => {
                setShowStatsModal(false);
                setGameOver(true);
                updateUserData();
                appContext.getPlayedCountsList(appContext.previousDays);
                navigate("/")
              }}
            >
              Main Menu
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function FeedbackModal({
  showFeedbackModal,
  feedback,
  setFeedback,
  feedbackSubmitted,
  setShowFeedbackModal,
  submitFeedback,
  grid,
  currentDay,
  setShowStatsModal,
}) {
  const appContext = useContext(AppContext);

  return (
    <div
      style={{
        ...styles.modalScreen,
        visibility: showFeedbackModal ? "visible" : "hidden",
      }}
    >
      <div style={{
        ...styles.modal,
        textAlign: "center",
        maxWidth: Math.round(appContext.SCREEN_WIDTH * 0.907)
      }}>
        <h1 style={styles.header}>
          {feedbackSubmitted ? "Submitted" : "Feedback"}
        </h1>
        <div
          style={{
            color: colors.header,
            textAlign: "center",
            marginBottom: 20,
          }}
        >
          {grid} Grid - <strong>{currentDay}</strong>
        </div>
        <div style={{ marginBottom: 20 }}>
          {feedbackSubmitted
            ? "Thanks for submitting your feedback for this puzzle!"
            : "Enter comments and feedback about this puzzle to help us improve future puzzles."}
        </div>
        {feedbackSubmitted ? null : (
          <input
            style={styles.input}
            type="text"
            placeholder="Enter your feedback..."
            onChange={(e) => setFeedback(e.target.value)}
            value={feedback}
          />
        )}
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: !feedbackSubmitted ? "50%" : "100%",
              marginRight: 5,
            }}
          >
            <button
              style={{
                ...styles.button,
                backgroundColor: "#5D6476",
                width: "100%",
              }}
              onClick={() => {
                setShowFeedbackModal(false);
                setShowStatsModal(true);
              }}
            >
              Back
            </button>
          </div>
          {!feedbackSubmitted && (
            <div style={{ width: "50%", marginLeft: 5 }}>
              <button
                style={{ ...styles.button, width: "100%" }}
                onClick={() => submitFeedback(feedback)}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const BUTTONHEIGHT = 65;
const INPUTHEIGHT = 54;

const styles = {
  gameScreen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    // height: "100vh", 
    paddingTop: 30,
    paddingBottom: 30
  },
  phrase: {
    fontSize: 36,
    fontWeight: "bold",
    color: colors.phrase,
    marginBottom: 25,
    textAlign: "center"
  },
  button: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
  disabledButton: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: colors.disabledButton,
    color: colors.buttonText,
    cursor: "not-allowed",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
  invertedButton: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: "transparent",
    color: colors.text,
    cursor: "pointer",
    height: BUTTONHEIGHT,
    border: "2px solid #5D6476",
    borderRadius: BUTTONHEIGHT / 2,
  },
  cell: {
    // maxWidth: 130,
    // maxHeight: 130,
    // width: "auto",
    // height: "auto",
    width: 130,
    height: 130,
    backgroundColor: "#C2DCBB",
    borderRadius: 10,
    objectFit: "cover",
    // display: "block",
  },
  modalScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(240, 246, 252, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 40,
    backgroundColor: "#F0F6FC",
    border: "1px solid #BBD1E7",
    borderRadius: 50,
    boxShadow: "0px 4px 25px rgba(64, 76, 85, 0.15)",
  },
  input: {
    backgroundColor: colors.input,
    maxWidth: 378,
    width: "90%",
    boxSizing: "border-box",
    height: INPUTHEIGHT,
    borderRadius: INPUTHEIGHT / 2,
    border: "none",
    textAlign: "left",
    fontSize: 18,
    color: colors.text,
    // color: "rgba(93, 100, 118, 0.5)",
    padding: "0px 22px 0px 22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: "10px 0px 20px 0px",
    marginBottom: 20,
  },
  divider: {
    height: 2,
    borderWidth: 0,
    width: "100%",
    backgroundColor: "#BBD1E7",
  },
  stat: {
    fontWeight: "bold",
    color: "#171D2E",
  },
  gridWrapper: {
    overflow: "auto",
  },
};
