import React, { useContext, useState, useEffect } from "react";
import { supabase } from "../supabase";

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    // Listen for changes on auth state (logged in, signed out, etc.)
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    syncUserData();

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  const syncUserData = async () => {
    const { data: { session }, error } = await supabase.auth.getSession();

    if (error) {
      setUser(null);
      setLoading(false);
    } else {
      setUser(session?.user);
      setLoading(false);
    }
  }
  // Will be passed down to Signup, Login and Dashboard components
  const value = {
    signUp: (data) => supabase.auth.signUp(data),
    signIn: (data) => supabase.auth.signInWithPassword(data),
    signOut: () => {
      supabase.auth.signOut()
      localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_NAME}-auth-token`);
      setUser(null);
    }, 
    updateUser: (data) => supabase.auth.updateUser(data), 
    user,
    syncUserData
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
