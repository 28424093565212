import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import colors from "../../config/colors";
import "./IntroScreen.css";

function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const items = [
    {
      title: "Welcome to",
      subtitle: "Subjects & Objects!",
      description: "",
    },
    {
      title: "",
      description:
        "Welcome to Subjects and Objects, a word/idea-association game with a twist. It plays on your understanding of the target language and your life experience with the language. It does not matter if you are a native or a learning/new speaker. You just might learn something new. There is no time limit in this game. We hope you find these puzzles entertaining and challenging.",
      icon: "/images/intro_illustration1.png",
      width: 136,
      height: 171,
    },
    {
      title: "",
      description:
        "Stimulate your cognitive abilities, practice mental agility, and improve your problem-solving skills with Subjects & Objects.",
      icon: "/images/intro_illustration2.png",
      width: 169,
      height: 171,
    },
  ];

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }
    setActiveIndex(newIndex);
  };

  const handleGetStarted = () => {
    localStorage.setItem("onboarded", "true");
  };

  function redirectOnboardedUser() {
    let onboarded = localStorage.getItem("onboarded");
    if (onboarded === "true") {
      return <Navigate to="/" />;
    }
  }

  return (
    <div>
      {redirectOnboardedUser()}
      <div
        className="inner"
        style={{ transform: `translate(-${activeIndex * 100}%)` }}
      >
        {items.map((item, index) => {
          return (
            <CarouselItem
              item={item}
              width={"100%"}
              key={index}
              activeIndex={activeIndex}
              numberOfItems={items.length}
              updateIndex={updateIndex}
              handleGetStarted={handleGetStarted}
            />
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 70,
            width: 65,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {items.map((item, index) => {
            return (
              <div
                style={{
                  backgroundColor: `rgba(93, 100, 118, ${
                    index === activeIndex ? "1" : "0.25"
                  })`,
                  width: 15,
                  height: 15,
                  borderRadius: 7.5,
                  cursor: "pointer",
                }}
                key={index}
                onClick={() => {
                  updateIndex(index);
                }}
              />
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 170,
          }}
        >
          {items.length === activeIndex + 1 ? (
            <Link to="/">
              <button
                style={{ ...styles.button, width: 310 }}
                onClick={() => handleGetStarted()}
              >
                Get Started
              </button>
            </Link>
          ) : // <button
          //   style={{ ...styles.button, width: 310 }}
          //   onClick={() => updateIndex(activeIndex + 1)}
          // >
          //   Continue
          // </button>
          null}
        </div>
      </div>
    </div>
  );
}

function CarouselItem({ item, width }) {
  return (
    <div className="carousel-item" style={{ width, height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span className="carousel-item-title">{item.title}</span>
        <span className="carousel-item-subtitle">{item.subtitle}</span>
        <img
          style={{ width: item.width, height: item.height }}
          src={item.icon}
          alt=""
        />
        <div className="carousel-item-text">{item.description}</div>
      </div>
    </div>
  );
}

function Test() {
  return (
    <div
      style={{
        backgroundColor: colors.bgLight,
      }}
    >
      <Carousel />
    </div>
  );
}

export default Test;

const BUTTONHEIGHT = 65;

const styles = {
  button: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
  title: {
    fontSize: 36,
  },
};
