import { useContext, useState, useEffect } from "react";
import { Link, useParams, useNavigate, Navigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import { supabase } from "../../supabase";
import Button from "../Button";
import { useAuth } from "../../contexts/Auth";

import "./ConfirmInvite.scss";

function ConfirmInvite({ user }) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const routeParams = useParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { email, referralId } = routeParams;

  const { signUp } = useAuth();

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      email === "" ||
      username === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      alert("Please enter all fields");
      return;
    }

    if (password.length < 6) {
      alert("Password needs to be at least 6 characters");
    }

    if (password !== confirmPassword) {
      alert("Password and confirm password don't match");
      return;
    }

    // can also do { data, error }
    const { data, error } = await signUp({
      email: email,
      password: password
    });


    if (error) {
      // setError(error);
      // setMessage("error with email or password");
      alert("error with email or password");
      return;
    }

    await appContext.insertProfile({
      user_id: data.user.id,
      username: username
    });

    await appContext.insertLanguage({
      user_id: data.user.id,
    });

    await appContext.insertConsecutive({
      user_id: data.user.id,
      consecutive: 0,
      ever_consecutive: 0
    });

    // If user was referred, update invitations
    if (referralId) {
      await updateInvitations(email, referralId);
    }

    // Redirect user to Dashboard
    localStorage.setItem("welcomeShown", "false");
    localStorage.setItem("leaderboardShown", "false");
    navigate("/");
  }

  const updateInvitations = async (email, referral_id) => {
    const { data, error } = await supabase
      .from("invitations")
      .insert([{ email, referral_id }]);
    // .select();

    if (error) {
      return console.error(error);
    }
  };


  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <form className="confirm-invite-container" onSubmit={handleSubmit}>
      <div className="form-wrapper"
        style={{
          width: Math.round(appContext.SCREEN_WIDTH * 0.907),
        }}
      >
        <div className="logo-wrapper">
          <img
            alt="Logo"
            src="/images/logo_inverse.png"
            onClick={() => navigate("/")}
          />
        </div>
        <div>
          <p className="form-header mt-50 mb-30">Confirm Invite</p>
          <input
            className="form-input"
            id="email"
            type="email"
            placeholder="Enter your email"
            disabled
            value={email}
          // onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="form-input"
            id="username"
            type="text"
            placeholder="Enter a username"
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="form-input"
            id="password"
            type="password"
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            className="form-input"
            id="confirmPassword"
            type="password"
            placeholder="Confirm password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="mt-20">
            <Button type="submit" buttonText="Continue" />
          </div>
        </div>
      </div>
    </form>
  )
}

export default ConfirmInvite;