import { useState, useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

const CDN_IMAGES_URL =
  process.env.REACT_APP_SUPABASE_URL + "/storage/v1/object/public/images/";

function ImageModal({ longPressed, magnifiedImage = {}, setLongPressed }) {
  const [naturalWidth, setNaturalWidth] = useState(0);
  const [naturalHeight, setNaturalHeight] = useState(0);

  const handleImageLoad = (e) => {
    setNaturalWidth(e.target.naturalWidth);
    setNaturalHeight(e.target.naturalHeight);
  }

  const appContext = useContext(AppContext);
  let imageUrl = "/images/default_image.svg";

  if (Object.entries(magnifiedImage).length > 0) {
    let { R_Path_Drive_Folder_File } = magnifiedImage;
    R_Path_Drive_Folder_File = R_Path_Drive_Folder_File.substring(45);
    imageUrl = CDN_IMAGES_URL + R_Path_Drive_Folder_File;
  }

  return (
    <div
      style={{
        ...styles.modalScreen,
        display: longPressed ? "flex" : "none",
      }}
      onClick={() => setLongPressed(false)}
    >
      <img
        onLoad={handleImageLoad}
        style={{
          ...styles.image,
          width: naturalWidth >= naturalHeight ? appContext.SCREEN_WIDTH * 0.907 : "auto",
          height: naturalWidth <= naturalHeight ? appContext.SCREEN_WIDTH * 0.907 : "auto"
        }} src={imageUrl} alt="" />
    </div>
  );
}

export default ImageModal;

const styles = {
  modalScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(240, 246, 252, 0.8)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    cursor: "pointer",
  },
  image: {
    // objectFit: "cover",
    borderRadius: 10,
  },
};
