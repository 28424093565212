import { Link } from "react-router-dom";
import colors from "../../config/colors";

function InfoModal({ showModal, setShowModal, text = "", homeScreen }) {
  return (
    <div
      style={{
        ...styles.modalScreen,
        visibility: showModal ? "visible" : "hidden",
      }}
    >
      <div style={styles.modal} onClick={() => setShowModal(!showModal)}>
        {homeScreen ? (
          <>
            <div style={{ textAlign: "center", margin: "10px 0 10px 0" }}>
              <p
                style={{
                  color: colors.phrase,
                  fontSize: 24,
                  fontWeight: "bold",
                }}
              >
                Sign Up
              </p>
              <p style={{ fontSize: 24 }}>to access previous puzzles.</p>
            </div>
            <hr style={styles.divider} />
            <div style={{ textAlign: "center", margin: "10px 0 0 0" }}>
              <Link to="/signup" style={{ ...styles.button, width: 310 }}>
                <button style={{ ...styles.button, width: 310 }}>
                  Sign Up
                </button>
              </Link>
            </div>
          </>
        ) : (
          text
        )}
      </div>
    </div>
  );
}

export default InfoModal;

const BUTTONHEIGHT = 65;

const styles = {
  modalScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(240, 246, 252, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // padding: "40px 0 40px 0",
    padding: 40,
    backgroundColor: "#F0F6FC",
    border: "1px solid #BBD1E7",
    borderRadius: 50,
    boxShadow: "0px 4px 25px rgba(64, 76, 85, 0.15)",
    // textAlign: "center",
    textAlign: "justify",
    textJustify: "inter-word",
    cursor: "pointer",
  },
  divider: {
    border: "1px solid #BBD1E7",
    width: 130,
  },
  button: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
};
