import { useState, useEffect, useContext } from "react";
import { Link, useNavigate, Navigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import Button from "../Button";
import colors from "../../config/colors";
import { supabase } from "../../supabase";
import { AppContext } from "../../contexts/AppContext";
import "../SignIn/SignIn.scss";

function SignUp({ match, location, user }) {
  const appContext = useContext(AppContext);
  const routeParams = useParams();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralId, setReferralId] = useState(null);

  // const [error, setError] = useState(null);
  // const [message, setMessage] = useState("");

  useEffect(() => {
    if (routeParams.referralId) {
      setReferralId(routeParams.referralId);
    }
  });

  // Get signUp function from the auth context
  const { signUp } = useAuth();

  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      email === "" ||
      username === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      alert("Please enter all fields");
      return;
    }

    if (password.length < 6) {
      alert("Password needs to be at least 6 characters");
    }

    if (password !== confirmPassword) {
      alert("Password and confirm password don't match");
      return;
    }

    // can also do { data, error }
    const { data, error } = await signUp({
      email: email,
      password: password
    });

    if (error) {
      // setError(error);
      // setMessage("error with email or password");
      alert("error with email or password");
      return;
    }

    await appContext.insertProfile({
      user_id: data.user.id,
      username: username
    });

    await appContext.insertLanguage({
      user_id: data.user.id,
    });

    await appContext.insertConsecutive({
      user_id: data.user.id,
      consecutive: 0, 
      ever_consecutive: 0
    });

    // If user was referred, update invitations
    if (referralId) {
      updateInvitations(email, referralId);
    }

    // Redirect user to Dashboard
    localStorage.setItem("welcomeShown", "false");
    localStorage.setItem("leaderboardShown", "false");
    navigate("/");

    // window.location.reload();
    // }
  }

  const updateInvitations = async (email, referral_id) => {
    const { data, error } = await supabase
      .from("invitations")
      .insert([{ email, referral_id }]);
    // .select();

    if (error) {
      return console.error(error);
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <form style={styles.signUpScreen} onSubmit={handleSubmit}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: Math.round(appContext.SCREEN_WIDTH * 0.907),
          // height: 932,
          height: "100vh",
          textAlign: "center",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div className="logo-wrapper">
          <img
            alt="Logo"
            src="/images/logo_inverse.png"
            onClick={() => navigate("/")}
          />
        </div>
        <div>
          <p style={styles.header}>Sign Up</p>
          <input
            style={styles.input}
            id="email"
            type="email"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            style={styles.input}
            id="username"
            type="text"
            placeholder="Enter a username"
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            style={styles.input}
            id="password"
            type="password"
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            style={styles.input}
            id="confirmPassword"
            type="password"
            placeholder="Confirm password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button type="submit" buttonText="Sign Up" />
        </div>
        <div style={{ paddingBottom: 60 }}>
          <p style={{ fontSize: 24 }}>
            Already have an account?{" "}
            <Link style={styles.link} to="/signin">
              Sign In
            </Link>
          </p>
          <div style={{ color: "rgba(93, 100, 118, 0.4)", marginTop: 10 }}>
            <p>By continuing you provide an agreement to our</p>
            <p>
              <a
                style={styles.secondaryLink}
                href="https://sno-app.webflow.io/"
              >
                Terms of Services
              </a>{" "}
              and{" "}
              <a
                style={styles.secondaryLink}
                href="https://sno-app.webflow.io/"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </form>
  );
}

export default SignUp;

const INPUTHEIGHT = 54;

const styles = {
  signUpScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#F0F6FC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  header: {
    color: colors.header,
    fontSize: 36,
    fontWeight: "bold",
  },
  input: {
    backgroundColor: colors.input,
    width: "100%",
    boxSizing: "border-box",
    height: INPUTHEIGHT,
    borderRadius: INPUTHEIGHT / 2,
    border: "none",
    textAlign: "left",
    fontSize: 18,
    color: colors.text,
    padding: "0px 22px 0px 22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 10px 0px",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
    color: colors.highlight,
  },
  secondaryLink: {
    textDecoration: "underline",
    fontWeight: "bold",
    color: "rgba(93, 100, 118, 0.4)",
  },
};
