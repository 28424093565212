import { useEffect, useState, useContext } from "react";
import * as xlsx from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import colors from "../../config/colors";
import InfoModal from "../InfoModal";
import moment from "moment";
import { AppContext } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/Auth";

import "./Home.scss"

const CDN_PUZZLES_URL =
  process.env.REACT_APP_SUPABASE_URL + "/storage/v1/object/public/puzzles/";
const BUTTONHEIGHT = 65;

const permanentEmails = [
  "petar.simic1290@gmail.com",
  "samson.au65@gmail.com",
  "omar.abubakar@gmail.com",
  "chloisa@yahoo.com",
  "wildcatx09@yahoo.ca",
  "simonnicholasjohn@gmail.com",
  "jc@danihelgroup.com",
  "mpeggyau@gmail.com",
];

function MessageModal({ showMessageModal, setShowMessageModal, message, day }) {
  const appContext = useContext(AppContext);
  return (
    <div
      style={{
        ...styles.modalScreen,
        visibility: showMessageModal ? "visible" : "hidden",
      }}
    >
      <div style={{
        ...styles.modal,
        textAlign: "center",
        width: Math.round(appContext.SCREEN_WIDTH * 0.907)
      }}>
        <h1 style={styles.header}>Message of the Day</h1>
        <div style={{ marginBottom: 20 }}>{message}</div>
        <button
          style={{
            fontSize: 18,
            fontWeight: 600,
            backgroundColor: colors.button,
            color: colors.buttonText,
            cursor: "pointer",
            height: BUTTONHEIGHT,
            border: "none",
            borderRadius: BUTTONHEIGHT / 2,
          }}
          onClick={() => {
            setShowMessageModal(false);
            let messagesOfTheDay = localStorage.getItem("messagesOfTheDay");
            let messagesSeen;
            if (!messagesOfTheDay) {
              messagesSeen = {
                [day]: true,
              };
            } else {
              messagesSeen = JSON.parse(messagesOfTheDay);
              messagesSeen[day] = true;
            }
            localStorage.setItem(
              "messagesOfTheDay",
              JSON.stringify(messagesSeen)
            );
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

function Home({ user }) {
  const appContext = useContext(AppContext);
  const inviteCount = appContext.inviteCount;
  const consecutiveDays = appContext.consecutiveDays;
  // const satisfyInvite = inviteCount > appContext.INVITE_LIMIT - 1 || permanentEmails.includes(user?.email.toLowerCase());
  // const satisfyConsecutive = consecutiveDays > appContext.CONSECUTIVE_DAYS_LIMIT - 1 || permanentEmails.includes(user?.email.toLowerCase());
  // const satisfyFill = (!!appContext.myProfile.birth_country && !!appContext.myProfile.residence_country
  //   && !!appContext.myProfile.high_level_education && !!appContext.myLanguage.home_language
  //   && !!appContext.myLanguage.work_language) || permanentEmails.includes(user?.email.toLowerCase());
  const satisfyPermanent = permanentEmails.includes(user?.email.toLowerCase());
  const puzzleMap = appContext.puzzleMap;
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  let day = moment().subtract(appContext.previousDays, "days").format("YYYYMMDD");

  useEffect(() => {
    if (!!user) {
      appContext.getConsecutiveDays();
      appContext.setIsTryAgainTaken(false);
    }
  }, [])

  useEffect(() => {
    if (!!user) {
      getPuzzlesAndMessage(appContext.previousDays);
      appContext.getPlayedCountsList(appContext.previousDays);
    }
  }, [appContext.previousDays]);

  async function getPuzzlesAndMessage(prevDays) {
    const puzzles = await appContext.getPuzzles(prevDays);
    if (!!puzzles["301"]) {
      await getMessage(puzzles);
    } else {
      appContext.setPreviousDays(appContext.previousDays + 1);
    }
  }

  async function handleSignOut() {
    // console.log("signing out");
    localStorage.setItem("splashShown", "false");

    // Ends user session
    await signOut();

    // Redirects the user to Login page
    navigate("/signin");
  }

  async function getMessage(puzzles) {
    let messagesOfTheDay = localStorage.getItem("messagesOfTheDay");
    // Only show the message of the day if the user has never seen that day's message before
    if (
      !messagesOfTheDay ||
      (messagesOfTheDay && !JSON.parse(messagesOfTheDay)[day])
    ) {
      getMessageOfTheDay(puzzles["301"]);
    }
  }

  // useEffect(() => {
  //   if (user) {
  //     getReferrals(user?.id);
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (Object.entries(puzzleMap).length) {
  //     console.log(puzzleMap);
  //     // const fetchData = async () => {
  //     //   let PUZZLE_URL = CDN_PUZZLES_URL + puzzle;
  //     //   const data = await (await fetch(PUZZLE_URL)).arrayBuffer();
  //     //   const workbook = xlsx.read(data);
  //     //   const sheetName = workbook.SheetNames[0];
  //     //   const worksheet = workbook.Sheets[sheetName];
  //     //   const json = xlsx.utils.sheet_to_json(worksheet);
  //     //   console.log(json);
  //     // };

  //     // fetchData();
  //   }
  // }, [puzzleMap]);

  const getMessageOfTheDay = async (puzzle) => {
    let PUZZLE_URL = CDN_PUZZLES_URL + puzzle;
    const puzzleData = await (await fetch(PUZZLE_URL)).arrayBuffer();
    const workbook = xlsx.read(puzzleData);
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json = xlsx.utils.sheet_to_json(worksheet);
    if (json.length > 0 && appContext.previousDays === 0) {
      setMessage(json[0].Message_Of_The_Day);
      setShowMessageModal(true);
    }
  };

  // const getReferrals = async (referralId) => {
  //   const { data, error } = await supabase
  //     .from("invitations")
  //     .select()
  //     .eq("referral_id", referralId);
  //   // .order("created_at", { ascending: false });

  //   if (error) {
  //     return console.error(error);
  //   }

  //   if (data) {
  //     let currentnewNumberOfReferrals =
  //       user.user_metadata?.statistics.referrals;
  //     let newNumberOfReferrals = data.length;
  //     if (currentnewNumberOfReferrals !== newNumberOfReferrals) {
  //       updateReferrals(newNumberOfReferrals);
  //     }
  //   }
  // };

  // const updateReferrals = async (newNumberOfReferrals) => {
  //   const { data, error } = await supabase.auth.updateUser({
  //     data: {
  //       statistics: {
  //         ...user.user_metadata.statistics,
  //         referrals: newNumberOfReferrals,
  //       },
  //     },
  //   });

  //   if (error) {
  //     console.log("Error updating statistics");
  //     return;
  //   }

  //   console.log("Successfully updated statistics", data);
  // };

  return (
    <>
      {!user || (!!user && (Object.keys(puzzleMap).length > 0 && appContext.playedCountsList)) ? <div style={styles.menu} className="pt-20 pb-20">
        <InfoModal showModal={showModal} setShowModal={setShowModal} homeScreen />
        <MessageModal
          showMessageModal={showMessageModal}
          setShowMessageModal={setShowMessageModal}
          message={message}
          day={day}
        />
        <div style={{ width: appContext.SCREEN_WIDTH, textAlign: "center" }}>
          <div
            style={{
              width: "90%",
              display: "inline-block",
            }}
          >
            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
              <img alt="Logo" src="/images/logo_inverse.png" />
            </div>
            {appContext.myProfile.username && (
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                Welcome back,
                <span style={{ color: colors.highlight }}> {appContext.myProfile.username}</span>!
              </p>
            )}
            <div
              style={{
                backgroundColor: colors.button,
                color: colors.buttonText,
                padding: 20,
                borderRadius: 50,
                fontWeight: "bold",
                margin: "20px 0px",
              }}
            >
              <p
                style={{ fontSize: 20, margin: "10px 0px", letterSpacing: "4px" }}
              >
                PUZZLE OF THE DAY
              </p>
              <p
                style={{
                  fontSize: 28,
                  display: "flex",
                  justifyContent: user ? "space-between" : "space-around",
                  margin: "20px 0px",
                }}
              >
                {user && (
                  <img
                    src="./images/chevron_left_white.svg"
                    style={{
                      cursor: appContext.previousDays === appContext.PREVIOUS_DAYS_LIMIT ? "none" : "pointer",
                      width: 25,
                      height: 25,
                      marginTop: 7,
                      pointerEvents: appContext.previousDays === appContext.PREVIOUS_DAYS_LIMIT ? "none" : "auto",
                      opacity: appContext.previousDays === appContext.PREVIOUS_DAYS_LIMIT ? 0.5 : 1,
                    }}
                    alt="Go Back"
                    onClick={() => appContext.setPreviousDays(appContext.previousDays + 1)}
                  />
                )}
                {moment().subtract(appContext.previousDays, "days").format("MMMM Do, YYYY")}
                {user && (
                  <img
                    src="./images/chevron_right_white.svg"
                    style={{
                      cursor: "pointer",
                      width: 25,
                      height: 25,
                      marginTop: 7,
                      visibility: appContext.previousDays === 0 ? "hidden" : "visible",
                    }}
                    alt="Go Forward"
                    onClick={() => appContext.setPreviousDays(appContext.previousDays - 1)}
                  />
                )}
              </p>

              {/* <Link to={puzzleAvailable ? "/game" : "#"} state={{ puzzle }}>
              <button
                style={
                  !puzzleAvailable
                    ? {
                        backgroundColor: colors.bgDark,
                        border: "none",
                        borderRadius: 50,
                        color: colors.buttonText,
                        cursor: "not-allowed",
                        fontSize: 18,
                        fontWeight: "bold",
                        height: 59,
                        marginTop: 10,
                        width: "100%",
                      }
                    : {
                        width: "100%",
                        height: 59,
                        backgroundColor: "#ffffff",
                        color: colors.phrase,
                        fontSize: 18,
                        fontWeight: "bold",
                        border: "none",
                        borderRadius: 50,
                        marginTop: 10,
                        cursor: "pointer",
                      }
                }
                disabled={!puzzleAvailable}
              >
                Play Today's Puzzle
              </button>
            </Link> */}

              {/* 3x3 Grids */}
              <div style={{ display: "flex" }}>
                {!!user ? (<div
                  style={{
                    width: satisfyPermanent || consecutiveDays > 1 ? "50%" : "100%",
                    marginRight: 5,
                  }}
                >
                  <Link
                    to={!puzzleMap["301"] ? "#" : "/game"}
                    state={{ puzzle: puzzleMap["301"] }}
                  >
                    <button
                      style={{
                        // !puzzleMap["301"] ? styles.disabledButton : styles.button
                        ...styles.button,
                        backgroundColor: !user ? colors.bgDark : appContext.playedCountsList["301"] === 1 ? "#dddddd" : "#ffffff"
                      }}
                    >
                      3x3 Game
                    </button>
                  </Link>
                </div>) : <div
                  style={{
                    width: "100%",
                    marginRight: 5,
                  }}
                >
                  <Link
                    to={"/freegame"}
                    state={{ puzzle: puzzleMap["301"] }}
                  >
                    <button
                      style={{
                        // !puzzleMap["301"] ? styles.disabledButton : styles.button
                        ...styles.button,
                        backgroundColor: "#ffffff"
                      }}
                    >
                      3x3 Game
                    </button>
                  </Link>
                </div>}
                {(satisfyPermanent || consecutiveDays > 1) && (
                  <div style={{ width: "50%", marginLeft: 5 }}>
                    <Link to="/game" state={{ puzzle: puzzleMap["303"] }}>
                      <button style={{
                        // !puzzleMap["301"] ? styles.disabledButton : styles.button
                        ...styles.button,
                        backgroundColor: appContext.playedCountsList["303"] === 3 ? "#dddddd" : "#ffffff"
                      }}>3x3 Game (3Q)</button>
                    </Link>
                  </div>
                )}
              </div>
              {/* {user && user?.user_metadata.statistics.referrals >= 5 && (puzzleMap["401"] || puzzleMap["404"]) && ( */}
              {(satisfyPermanent || consecutiveDays > 0) && (
                // 4x4 Grids
                <div style={{ display: "flex", marginTop: 20 }}>
                  <div style={{
                    width: (satisfyPermanent || consecutiveDays > 2) ? "50%" : "100%",
                    marginRight: 5
                  }}>
                    <Link to="/game" state={{ puzzle: puzzleMap["401"] }}>
                      <button style={{
                        // !puzzleMap["301"] ? styles.disabledButton : styles.button
                        ...styles.button,
                        backgroundColor: appContext.playedCountsList["401"] === 1 ? "#dddddd" : "#ffffff"
                      }}>4x4 Game</button>
                    </Link>
                  </div>
                  {(satisfyPermanent || consecutiveDays > 2) && <div style={{ width: "50%", marginLeft: 5 }}>
                    <Link to="/game" state={{ puzzle: puzzleMap["404"] }}>
                      <button style={{
                        // !puzzleMap["301"] ? styles.disabledButton : styles.button
                        ...styles.button,
                        backgroundColor: appContext.playedCountsList["404"] === 4 ? "#dddddd" : "#ffffff"
                      }}>4x4 Game (4Q)</button>
                    </Link>
                  </div>}
                </div>
              )}
            </div>
            <div className="links-wrapper mb-20">
              {!!user && <Link to="/statistics"
                style={{ width: "31%" }}
              >
                <button>Statistics</button>
              </Link>}
              <Link to="/demo"
                style={{ width: !!user ? "30%" : "100%" }}
              >
                <button>Demo</button>
              </Link>
              {!!user && <Link to="/leaderboard"
                style={{ width: "36%" }}
              >
                <button>Leaderboard</button>
              </Link>}
            </div>
            {user && <Link to="/referral">
              <button style={{
                ...styles.howToPlayButton,
                marginTop: 0,
              }} className="mb-10">
                Invite Friends
              </button>
            </Link>}
            {user ? (
              <div
                style={{
                  backgroundColor: colors.secondaryButton,
                  color: colors.buttonText,
                  padding: 20,
                  borderRadius: 50,
                  fontWeight: "bold",
                  margin: "10px 0px",
                }}
              >
                <p
                  style={{
                    fontSize: 20,
                    margin: "10px 0px",
                    letterSpacing: "4px",
                  }}
                >
                  MORE FEATURES
                </p>
                <Link to="/upgrades">
                  <button
                    style={{
                      width: "100%",
                      height: 59,
                      backgroundColor: "#ffffff",
                      color: colors.secondaryButton,
                      fontSize: 18,
                      fontWeight: "bold",
                      border: "none",
                      borderRadius: 50,
                      marginTop: 10,
                      cursor: "pointer",
                    }}
                  >
                    Upgrades
                  </button>
                </Link>
              </div>
            ) : (
              <button
                style={{ ...styles.disabledButton, marginBottom: 10 }}
                onClick={() => setShowModal(!showModal)}
              >
                Play Previous Puzzles
              </button>
            )}
            {!!user && <div className="mt-10">
              <button
                style={{ ...styles.howToPlayButton, color: "#F94646" }}
                onClick={handleSignOut}
              >
                {/* <img
                style={styles.settingsIcon}
                alt="Sounds"
                src={`/images/sign_out_icon.svg`}
              /> */}
                Sign Out
              </button>
            </div>}
            {!appContext.myProfile.username && (
              <div style={{ fontSize: 24, textAlign: "center", marginTop: 20 }}>
                <p>
                  <Link
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: colors.highlight,
                    }}
                    to="/signin"
                  >
                    Sign In
                  </Link>{" "}
                  or{" "}
                  <Link
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: colors.highlight,
                    }}
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </p>
                <p>to access more features.</p>
              </div>
            )}
          </div>
        </div>
        {/* For debugging */}
        {/* <button onClick={handleLogout}>Logout</button> */}
      </div> :
        <div className="loading-screen">
          loading ...
        </div>}
    </>
  );
}

export default Home;

const styles = {
  menu: {
    // position: "fixed",
    // padding: 0,
    // margin: 0,
    // top: 0,
    // left: 0,
    // width: "100%",
    // height: "100%",
    width: "100vw",
    // height: "90vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F0F6FC",
    // zIndex: 1,
  },
  header: {
    color: colors.header,
    fontSize: 36,
    fontWeight: "bold",
    marginBottom: 20,
  },
  subheader: {
    color: colors.header,
    fontSize: 36,
    marginBottom: 20,
  },
  previousButton: {
    backgroundColor: colors.button,
    color: colors.buttonText,
    cursor: "pointer",
    width: 310,
    height: BUTTONHEIGHT,
    border: "none",
    borderRadius: BUTTONHEIGHT / 2,
  },
  howToPlayButton: {
    backgroundColor: "#F0F6FC",
    color: colors.text,
    fontSize: 18,
    fontWeight: 600,
    cursor: "pointer",
    width: "100%",
    height: 59,
    border: "2px solid #5D6476",
    borderRadius: 30,
    marginTop: 10,
  },
  button: {
    width: "100%",
    height: 59,
    backgroundColor: "#ffffff",
    color: colors.phrase,
    fontSize: 18,
    fontWeight: "bold",
    border: "none",
    borderRadius: 50,
    cursor: "pointer",
  },
  disabledButton: {
    width: "100%",
    height: 59,
    backgroundColor: colors.bgDark,
    color: colors.buttonText,
    fontSize: 18,
    fontWeight: "bold",
    border: "none",
    borderRadius: 50,
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
    color: colors.highlight,
  },
  modalScreen: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(240, 246, 252, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 40,
    backgroundColor: "#F0F6FC",
    border: "1px solid #BBD1E7",
    borderRadius: 50,
    boxShadow: "0px 4px 25px rgba(64, 76, 85, 0.15)",
  },
  settingsIcon: {
    width: 22,
    height: 22,
    marginRight: 12,
    marginTop: 5,
  },
};
