import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../GameScreen/GameScreen";
import { supabase } from "../../supabase"
import './UpdatePassword.scss'
import { AppContext } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/Auth";

export default function UpdatePassword() {
  const authContext = useAuth();
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleConfirm = async () => {
    const { data, error } = await supabase.auth.updateUser({ password: password });

    if (data) {
      setSuccessMessage("Password updated successfully!");
      setErrorMessage("");
      navigate("/")
    }
    
    if (error) {
      setErrorMessage("There was an error updating your password.");
      setSuccessMessage("");
    }
  }

  if (!authContext.user) {
    navigate("/");
  }

  return (
    <div className="update-password-container"
      style={{
        maxWidth: appContext.SCREEN_WIDTH
      }}
    >
      <div className="update-password-wrapper">
        <p className="text-bold mb-30">Type your new password</p>
        <input
          className="password-input"
          type="email"
          disabled
          rows={1}
          placeholder="Email"
          autoComplete="on"
          // onChange={(e) => setPassword(e.target.value)}
          name="emailContent"
          value={authContext.user?.email}
        />
        <input
          className="password-input mt-10"
          type="password"
          required
          rows={1}
          placeholder="Password"
          autoComplete="on"
          onChange={(e) => setPassword(e.target.value)}
          name="emailContent"
          value={password}
        />

        <input
          className="password-input mt-10"
          type="password"
          required
          rows={1}
          placeholder="Confirm Password"
          autoComplete="on"
          onChange={(e) => setConfirmPassword(e.target.value)}
          name="emailContent"
          value={confirmPassword}
        />
        {successMessage != "" && <p className="text-success mt-10">{successMessage}</p>}
        {errorMessage != "" && <p className="text-danger mt-10">{errorMessage}</p>}
        <div>
          <br />
          <Button
            buttonText="Confirm"
            width={200}
            disabled={password === "" || password !== confirmPassword}
            handleConfirmSelection={handleConfirm}
          />
        </div>
      </div>
    </div>
  );
}
